import React from "react";
import Orders from "../../../Features/Tables/PurchaseOrders";

const AllOrders = () => {
  return (
    <div>
      <Orders />
    </div>
  );
};

export default AllOrders;
