import Label from "./Label";

import InputError from "./InputError";

function Input(props) {
  const { mt = 1.2, mb = 1.2, children, input = null, validate = 0 } = props;

  return (
    <div className=" w-full" style={{}}>
      <Label input={input} validate={validate} />
      <div
        className={`w-full flex flex-col border-2 rounded-md   ${
          input?.error ? " border-red" : ""
        }`}
      >
        {children}
      </div>
      <InputError input={input} validate={validate} />
    </div>
  );
}

export default Input;
