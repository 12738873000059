import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Summary from "../Screens/Summary";
import Dashboard from "../Screens/Dashboard";
import SignIn from "../Screens/SignIn";

// Members
import Members from "../Screens/Members";
import AllMembers from "../Screens/Members/All";
import AddMember from "../Screens/Members/Add";
import EditMember from "../Screens/Members/Edit";
import ViewMember from "../Screens/Members/View";

//Vehicles
import Vehicles from "../Screens/Vehicles";
import AllVehicles from "../Screens/Vehicles/All";
import AddVehicle from "../Screens/Vehicles/Add";
import EditVehicle from "../Screens/Vehicles/Edit";
import ViewVehicle from "../Screens/Vehicles/View";

//vehicles logistics
import Logistics from "../Screens/Logistics";
import AllLogistics from "../Screens/Logistics/All";
import AddLogistic from "../Screens/Logistics/Add";
import EditLogistic from "../Screens/Logistics/Edit";
import ViewLogistic from "../Screens/Logistics/View";

//vehicle maintenance
import Maintenance from "../Screens/Maintanance";
import AllMaintenanceData from "../Screens/Maintanance/All";
import AddMaintenanceData from "../Screens/Maintanance/Add";
import EditMaintenanceData from "../Screens/Maintanance/Edit";
import ViewMaintenanceData from "../Screens/Maintanance/View";

//Routes
import FleetRoutes from "../Screens/FleetRoutes";
import AllFleetRoutes from "../Screens/FleetRoutes/All";
import AddRoute from "../Screens/FleetRoutes/Add";
import EditRoute from "../Screens/FleetRoutes/Edit";
import ViewRoute from "../Screens/FleetRoutes/View";

//Devices
import Devices from "../Screens/Devices";
import AllDevices from "../Screens/Devices/All";
import AddDevice from "../Screens/Devices/Add";
import EditDevice from "../Screens/Devices/Edit";
import ViewDevice from "../Screens/Devices/View";
import AddVehicleDevice from "../Screens/Devices/View/AddVehicles";

//Fuel Records
import FuelRecords from "../Screens/FuelManagement";
import AllFuelRecords from "../Screens/FuelManagement/All";
import AddFuelRecord from "../Screens/FuelManagement/Add";
import EditFuelRecord from "../Screens/FuelManagement/Edit";
import ViewFuelRecord from "../Screens/FuelManagement/View";

// Roles
import Roles from "../Screens/Roles";
import AllRoles from "../Screens/Roles/All";
import AddRole from "../Screens/Roles/Add";
import EditRole from "../Screens/Roles/Edit";

// Requests
import Requests from "../Screens/Requests";
import AllRequests from "../Screens/Requests/All";
import AddRequest from "../Screens/Requests/Add";
import EditRequest from "../Screens/Requests/Edit";

// Purchase Orders
import PurchaseOrders from "../Screens/PurchaseOrders";
import AllOrders from "../Screens/PurchaseOrders/All";
import AddOrder from "../Screens/PurchaseOrders/Add";
import EditOrder from "../Screens/PurchaseOrders/Edit";

// Inventory
import Inventory from "../Screens/Inventory";
import AllInventory from "../Screens/Inventory/All";
import AddInventory from "../Screens/Inventory/Add";
import EditInventory from "../Screens/Inventory/Edit";
import ViewInventory from "../Screens/Inventory/View";

//Reports//
import Reports from "../Screens/Reports";
//Vehicle reports
import VehicleReports from "../Screens/Reports/VehicleReports";
import AllVehicleReports from "../Screens/Reports/VehicleReports/All";
import AddVehicleReports from "../Screens/Reports/VehicleReports/Add";
import EditVehicleReports from "../Screens/Reports/VehicleReports/Edit";

//Fuel reports
import FuelReport from "../Screens/Reports/FuelReport";
import AllFuelReports from "../Screens/Reports/FuelReport/All";
import AddFuelReport from "../Screens/Reports/FuelReport/Add";
import EditFuelReport from "../Screens/Reports/FuelReport/Edit";

//Trip reports
import TripReport from "../Screens/Reports/TripReport";
import AllTripReports from "../Screens/Reports/TripReport/All";
import AddTripReport from "../Screens/Reports/TripReport/Add";
import EditTripReport from "../Screens/Reports/TripReport/Edit";
import Protected from "../Protected";

// Finance
import Finance from "../Screens/Finance";
import AllFinance from "../Screens/Finance/All";

// Monitoring
import Monitoring from "../Screens/Monitoring";
import AllMonitoring from "../Screens/Monitoring/All";

//viewtracks
import ViewTracks from "../Screens/ViewTracks";
import AllViewTracks from "../Screens/ViewTracks/All";

//TrackAssets
import ViewAssets from "../Screens/ViewAssets";
import AllViewAssets from "../Screens/ViewAssets/All";

//Company
import Company from "../Screens/Company";
import AllCompany from "../Screens/Company/components";

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<SignIn />} />
        {/* Everything including the dashboard should be wrapped in the Protected component  */}
        <Route element={<Protected />}>
          <Route path="dashboard" element={<Dashboard />}>
            <Route path="" element={<Summary />} />
            <Route path="members" element={<Members />}>
              <Route path="" element={<AllMembers />} />
              <Route path="add" element={<AddMember />} />
              <Route path="edit/:id" element={<EditMember />} />
              <Route path="view/:id" element={<ViewMember />} />
            </Route>

            <Route path="company-details" element={<Company />}>
                <Route path="" element={<AllCompany />} />
              </Route>

            <Route path="vehicles" element={<Vehicles />}>
              <Route path="fleets" element={<Vehicles />}>
                <Route path="" element={<AllVehicles />} />
                <Route path="add" element={<AddVehicle />} />
                <Route path="edit/:id" element={<EditVehicle />} />
                <Route path="view/:id" element={<ViewVehicle />} />
              </Route>
              <Route path="dispatch" element={<Logistics />}>
                <Route path="" element={<AllLogistics />} />
                <Route path="add" element={<AddLogistic />} />
                <Route path="edit/:id" element={<EditLogistic />} />
                <Route path="view/:id" element={<ViewLogistic />} />
              </Route>
              <Route path="maintenance" element={<Maintenance />}>
                <Route path="" element={<AllMaintenanceData />} />
                <Route path="add" element={<AddMaintenanceData />} />
                <Route path="edit/:id" element={<EditMaintenanceData />} />
                <Route path="view/:id" element={<ViewMaintenanceData />} />
              </Route>
            </Route>

            <Route path="finance" element={<Finance />}>
              <Route path="" element={<AllFinance />} />
            </Route>

            <Route path="monitoring" element={<Monitoring />}>
              <Route path="" element={<AllMonitoring />} />
            </Route>
            <Route path="monitoring/viewtracks" element={<ViewTracks />}>
              <Route path="" element={<AllViewTracks />} />
            </Route>
            <Route path="monitoring/trackassets" element={<ViewAssets />}>
              <Route path="" element={<AllViewAssets />} />
            </Route>

            <Route path="routes" element={<FleetRoutes />}>
              <Route path="" element={<AllFleetRoutes />} />
              <Route path="add" element={<AddRoute />} />
              <Route path="edit/:id" element={<EditRoute />} />
              <Route path="view/:id" element={<ViewRoute />} />
            </Route>
            <Route path="fuel" element={<FuelRecords />}>
              <Route path="" element={<AllFuelRecords />} />
              <Route path="add" element={<AddFuelRecord />} />
              <Route path="edit/:id" element={<EditFuelRecord />} />
              <Route path="view/:id" element={<ViewFuelRecord />} />
            </Route>
            <Route path="devices" element={<Devices />}>
              <Route path="" element={<AllDevices />} />
              <Route path="add" element={<AddDevice />} />
              <Route path="edit/:id" element={<EditDevice />} />
              <Route path="view/:id" element={<AddVehicleDevice />} />
            </Route>
            <Route path="roles" element={<Roles />}>
              <Route path="" element={<AllRoles />} />
              <Route path="add" element={<AddRole />} />
              <Route path="edit/:id" element={<EditRole />} />
            </Route>
            <Route path="requests" element={<Requests />}>
              <Route path="" element={<AllRequests />} />
              <Route path="add" element={<AddRequest />} />
              <Route path="edit/:id" element={<EditRequest />} />
            </Route>
            <Route path="purchase-orders" element={<PurchaseOrders />}>
              <Route path="" element={<AllOrders />} />
              <Route path="add" element={<AddOrder />} />
              <Route path="edit/:id" element={<EditOrder />} />
            </Route>
            <Route path="inventory" element={<Inventory />}>
              <Route path="" element={<AllInventory />} />
              <Route path="add" element={<AddInventory />} />
              <Route path="edit/:id" element={<EditInventory />} />
              <Route path="view/:id" element={<ViewInventory />} />
            </Route>
            <Route path="reports" element={<Reports />}>
              <Route path="vehicle" element={<VehicleReports />}>
                <Route path="" element={<AllVehicleReports />} />
                <Route path="add" element={<AddVehicleReports />} />
                <Route path="edit/:id" element={<EditVehicleReports />} />
              </Route>
              <Route path="fuel" element={<FuelReport />}>
                <Route path="" element={<AllFuelReports />} />
                <Route path="add" element={<AddFuelReport />} />
                <Route path="edit/:id" element={<EditFuelReport />} />
              </Route>
              <Route path="trip" element={<TripReport />}>
                <Route path="" element={<AllTripReports />} />
                <Route path="add" element={<AddTripReport />} />
                <Route path="edit/:id" element={<EditTripReport />} />
              </Route>
            
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
