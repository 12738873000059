import React from "react";
import Provider from "./Provider";
import AllRoutes from "./Routes";

const Index = () => {
  return (
    <div>
      <Provider>
        <AllRoutes />
      </Provider>
    </div>
  );
};

export default Index;
