const initial_user = {
    email: {
      label: "Email Address",
      value: "",
      placeholder: "johndoe@gmail.com",
      required: true,
      error: false,
      validation: {
        minChar: {
          value: 4,
          message: "Email too short",
        },
      },
      transform: "lowerCase",
    },
    password: {
      value: "",
      placeholder: "Enter password",
      error: false,
      required: true,
      e_message: "* input error",
      label: "Password",
      validation: {
        minChar: {
          value: 4,
          message: "Passsword too short",
        },
      },
    },
    checkbox: {
      value: true,
      label: "Remember Me?",
    },
  };
  
  export default initial_user;