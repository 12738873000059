function minCharValidation(inp, value) {
  try {
    if (typeof value !== "number") return false;
    if (typeof inp !== "string") return false;

    if (inp.length < value) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(e.message);
    return false;
  }
}

function pattenValidation(inp, value) {
  try {
    if (typeof inp !== "string") return false;

    const regexPattern = new RegExp(value, "g");

    const isMatch = regexPattern.test(inp);

    return !isMatch;
  } catch (e) {
    console.error(e.message);
    return false;
  }
}

function matchInput() {}

function eMatch(inp, value) {
  try {
    let sinp = inp?.toString();
    let svalue = value?.toString();

    if (sinp === svalue) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(e.message);
    return false;
  }
}

function falseyInput(inp) {
  try {
    if (!inp) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(e.message);
    return false;
  }
}

function minNo(inp, value) {
  try {
    let inp_v = parseInt(inp);
    let value_v = parseInt(value);

    if (!inp_v || !value_v) {
      return;
    }

    if (value_v > inp_v) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(e.message);
    return false;
  }
}

function maxNo(inp, value) {
  try {
    let inp_v = parseInt(inp);
    let value_v = parseInt(value);

    if (!inp_v || !value_v) {
      return;
    }

    if (inp_v > value_v) {
      return true;
    }
    return false;
  } catch (e) {
    console.error(e.message);
    return false;
  }
}

export default {
  minChar: minCharValidation,
  pattern: pattenValidation,
  matchInput,
  eMatch,
  falseyInput,
  maxNo,
  minNo,
};
