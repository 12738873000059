import React from "react";
import Inventory from "../../../Features/Tables/Inventory";

const All = () => {
  return (
    <div>
      <Inventory />
    </div>
  );
};

export default All;
