const init_po = {
  item_name: {
    value: "",
    label: "Item Name",
    placeholder: "Enter item name",
  },
  quantity: {
    value: "",
    label: "Quantity",
    placeholder: "Enter quantity",
  },
  total_amount: {
    value: "",
    lable: "Total Amount",
    placeholder: "Enter total amount",
  },
  description: {
    value: "",
    label: "Description",
    placeholder: "Enter description",
  },
};

export default init_po;
