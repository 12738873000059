import React from "react";
import Members from '../../../Features/Tables/Members'

const All = () => {
  return <div>
    <Members />
  </div>;
};

export default All;
