const init_inventory = {
  item_name: {
    value: "",
    label: "Item Name",
    placeholder: "Enter item name",
  },
  quantity: {
    value: "",
    label: "Quantity",
    placeholder: "Enter quantity",
  },
  total_cost: {
    value: "",
    label: "Cost",
    placeholder: "Enter cost",
  },
  cost_per_item: {
    value: "",
    label: "Cost Per Item",
    placeholder: "Enter cost per item",
  },
  warehouse_id: {
    value: "",
    label: "Warehouse",
    placeholder: "Enter warehouse name",
  },
  purchase_order_id:{
    value: "",
    label: "Purchase Order",
    placeholder: "Enter purchase order",
  },

  
};

export default init_inventory;
