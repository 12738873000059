import React, { useEffect, useState } from "react";
import { TextInput, SelectInput, DateInput } from "../../Components/Inputs";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import useForm from "../../Components/hooks/useForm";
import init_devices from "../init_devices";

const Edit = () => {
  const { id } = useParams();
  const [input, setInput] = useState(init_devices);
  const navigate = useNavigate();
  const request = useAxios();

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    getDeviceDetails();
  }, []);

  async function getDeviceDetails() {
    let res = await request({
      method: "get",
      url: `devices/single-device`,
      params: {
        id: parseInt(id),
      }
    });
    if (res === "error") return;
    const inputCpy = {...init_devices}
    inputCpy.FAssetID.value = res?.FAssetID;
    inputCpy.FVehicleGUID.value = res?.FVehicleGUID;
    inputCpy.FAssetTypeID.value = res?.FAssetTypeID;
    inputCpy.FSIMNumber.value = res?.FSIMNumber;
    inputCpy.FVehicleName.value = res?.FVehicleName;
    inputCpy.FDescription.value = res?.FDescription;

    setInput(inputCpy)

  }
  const handleSubmit = async (data) => {
    setIsLoading(true);
    let res = await request({
      method: "PUT",
      url: `devices/update`,
      body: {
        id: parseInt(id),
        ...data,
      },
      show_loading: true,
      loadingFunc: setIsLoading,
    });
    if (res === "error") return;
    navigate("/dashboard/devices");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Edit Device</h3>
      <div className="mt-4">
        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="FAssetID"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="FVehicleGUID"
            validate={validate}
          />
        </div>
  
        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="FAssetTypeID"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="FSIMNumber"
            validate={validate}
          />
        </div>
  
        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="FVehicleName"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="FDescription"
            validate={validate}
          />
        </div>
      </div>
  
      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/devices")}>Back</Btn>
        <Btn  onClick={handleValidation}
          disabled={isLoading}
        >
          {isLoading ? 'updating...': 'Submit'}</Btn>
      </div>
    </div>
  );
  
};

export default Edit;


