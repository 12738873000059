import React, { useEffect, useState } from "react";
import { TextInput, SelectInput } from "../../../Components/Inputs";
import Btn from "../../../../../Components/Buttons/Btn";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../Hooks/useAxios";
import useForm from "../../../Components/hooks/useForm";
import init_records from "../init_records";

const Edit = () => {
  const { id } = useParams();
  const [input, setInput] = useState(init_records);
  const navigate = useNavigate();
  const request = useAxios();

  useEffect(() => {
    getFuelDetails();
  }, []);

  async function getFuelDetails() {
    let res = await request({
      method: "get",
      url: `fuel/${id}`,
      show_loading: false,
      show_error: false,
    });
    if (res !== "error") {
      setInput(res?.doc);
    }
  }

  const handleSubmit = async (data) => {
    let res = await request({
      method: "PUT", 
      url: `fuel/update/${id}`,
      body: data,
    });
    if (res === "error") return;
    navigate("/dashboard/fuel");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Edit Fuel Record</h3>
      <div className="mt-4">

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <TextInput
            input={input}
            setInput={setInput}
            field="driver"
            validate={validate}
            label="Driver"
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="reg_number"
            validate={validate}
            label="Vehicle Reg"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-4">
          <TextInput
            input={input}
            setInput={setInput}
            field="fuel_type"
            validate={validate}
            label="Fuel Type"
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="quantity"
            validate={validate}
            label="Quantity (Liters)"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-4">
          <TextInput
            input={input}
            setInput={setInput}
            field="cost"
            validate={validate}
            label="Cost per Liter"
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="station"
            validate={validate}
            label="Fuel Station"
          />
        </div>

          <TextInput
            input={input}
            setInput={setInput}
            field="efficiency"
            validate={validate}
            label="Fuel Efficiency (Km/L)"
          />

      </div>
      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/fuel")}>Back</Btn>
        <Btn onClick={handleValidation}>Update</Btn>
      </div>
    </div>
  );
};

export default Edit;



