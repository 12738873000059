import React from "react";
import EditRequest from "../../../Features/Forms/Requests/Edit";

const Edit = () => {
  return (
    <div>
      <EditRequest />
    </div>
  );
};

export default Edit;
