import { useState, useEffect } from "react";

function useRequestSuccessModal() {
  const [showRequestSuccessModal, setShowRequestSuccessModal] = useState(false);
  const [requestSuccessMessage, setRequestSuccessMessage] =
    useState("Success Message");
  const [requestSuccessMessageTimeout, setRequestSuccessMessageTimeout] =
    useState(20000);
  const [requestSuccessHeader, setRequestSuccessHeader] = useState("Sucess");

  useEffect(() => {
    if (showRequestSuccessModal) {
      return;
    }
    setRequestSuccessMessage("Success Message");
    setRequestSuccessMessageTimeout(20000);
    setRequestSuccessHeader("Sucess");
  }, [showRequestSuccessModal]);

  const handleShow = (obj = {}) => {
    const { header = "Sucess", message = "Sucess Message" } = obj;
    setRequestSuccessHeader(header);
    setRequestSuccessMessage(message);
    setShowRequestSuccessModal(true);
  };

  //console.log(showRequestSuccessModal);

  return {
    showRequestSuccessModal,
    requestSuccessMessage,
    setRequestSuccessMessage,
    requestSuccessMessageTimeout,
    setRequestSuccessMessageTimeout,
    setShowRequestSuccessModal,
    requestSuccessHeader,
    setRequestSuccessHeader,
    handleShow,
  };
}

export default useRequestSuccessModal;
