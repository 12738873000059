import React, { useEffect, useState } from "react";
import { TextInput, SelectInput, DateInput } from "../../Components/Inputs";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import useForm from "../../Components/hooks/useForm";
import init_routes from "../init_routes";

const Edit = () => {
  const { id } = useParams();
  const [input, setInput] = useState(init_routes);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const request = useAxios();

  useEffect(() => {
    getRouteDetails();
  }, []);

  async function getRouteDetails() {
    let res = await request({
      method: "get",
      url: 'routes/single-route',
      params:{
        id: parseInt(id)
      }
    });
    if (res === "error") return;
    const inputCpy = {...init_routes};
    inputCpy.name.value = res?.name;
    inputCpy.no_of_stops.value = res?.no_of_stops;
    inputCpy.description.value = res?.description;

   setInput(inputCpy)
  }

  const handleSubmit = async (data) => {
    let res = await request({
      method: "PUT", 
      url: 'routes/update',
      body: {...data, id:parseInt(id)},
      show_loading: true,
        loadingFunc: setIsLoading,
    });
    if (res === "error") return;
    navigate("/dashboard/routes");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Edit Route</h3>
      <div className="mt-4">
        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="name"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="no_of_stops"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="description"
            validate={validate}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/routes")}>Back</Btn>
        <Btn onClick={handleValidation}>Update</Btn>
      </div>
    </div>
  );
};

export default Edit;


