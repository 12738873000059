import React from "react";
import Add from "../../../Features/Forms/Roles/Add";

const AddRole = () => {
  return (
    <div>
      <Add />
    </div>
  );
};

export default AddRole;
