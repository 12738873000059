import Btn from "./Btn";

import { FaPlus } from "react-icons/fa";

function AddBtn(props) {
  const { handleAdd = null, Addlabel = "Add", icon = <FaPlus /> } = props;

  if (typeof handleAdd !== "function") {
    return null;
  }

  return (
    <div
      onClick={handleAdd}
      className="bg-primary cursor-pointer rounded-sm py-2 px-4 max-md:w-full flex justify-center"
    >
      <div className="flex items-center gap-2">
        <p className="text-white font-semibold">{icon}</p>
        <span className="text-white font-semibold">{Addlabel}</span>
      </div>
    </div>
  );
}

export default AddBtn;
