import React, { useState } from "react";
import AppContext from "./AppContext";
import useLoadingModal from "../Hooks/useLoadingModal";
import LoadingModal from "../Components/Modals/LoadingModal";
import RequestErrorModal from "../Components/Modals/RequestErrorModal";
import RequestSuccessModal from "../Components/Modals/RequestSuccessModal";
import useRequestErrorModal from "../Hooks/useRequestErrorModal";
import useRequestSuccessModal from "../Hooks/useRequestSuccessModal";
import useDevice from "../Hooks/useDevice";

const Provider = ({ children }) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const requestErrorModal = useRequestErrorModal();
  const requestSuccessModal = useRequestSuccessModal();
  const loadingModal = useLoadingModal();
  const device = useDevice();
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [user, setUser] = useState();

  const [sideBarOpen, setSideBarOpen] = useState(false);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        isLoading,
        setIsLoading,
        breadcrumbItems,
        setBreadcrumbItems,
        device,
        requestErrorModal,
        requestSuccessModal,
        loadingModal,
        sideBarOpen,
        setSideBarOpen,
      }}
    >
      {children}
      <>
        <RequestErrorModal requestErrorModal={requestErrorModal} />
        <RequestSuccessModal requestSuccessModal={requestSuccessModal} />
        <LoadingModal loadingModal={loadingModal} />
      </>
    </AppContext.Provider>
  );
};

export default Provider;
