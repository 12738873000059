const init_records = {
  driver: {
    value: "",
    label: "Driver",
    placeholder: "Enter Driver Name",
  },
  reg_number: {
    value: "",
    label: "Vehicle Reg",
    placeholder: "Enter Number Plate",
  },
  fuel_type: {
    value: "",
    label: "Fuel Type",
    placeholder: "Enter Fuel Type",
  },
  quantity: {
    value: "",
    label: "Fuel Quantity",
    placeholder: "Quantity in Litres",
  },
  cost: {
    value: "",
    label: "Total Fuel Cost",
    placeholder: "Enter Total Cost in Kshs",
  },
  station: {
    value: "",
    label: "Fuel Station",
    placeholder: "Enter Station Name",
  },
  efficiency: {
    value: "",
    label: "Fuel Efficiency",
    placeholder: "KM/Litre",
  },

};

export default init_records;
