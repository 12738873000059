import React from "react";
import Maintenance from "../../../Features/Tables/Maintanance";

const All = () => {
  return <div>
    <Maintenance />
  </div>;
};

export default All;
