import React, { useEffect, useState } from "react";
import {
  TextInput,
  SelectInput,
  DateInput,
  TimeInput,
} from "../../Components/Inputs";
import SearchableSelect from "../../Components/Inputs/SearchableSelect";
import init_dispatch from "../init_dispatch";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import useForm from "../../Components/hooks/useForm";
import useApp from "../../../../Hooks/useApp";

const Add = () => {
  const [input, setInput] = useState(init_dispatch);
  const navigate = useNavigate();
  const request = useAxios();

  const [vehicles, setVehicles] = useState({});
  const [routes, setRoutes] = useState({});
  const [members, setMembers] = useState({});

  const [tripStatuses, setTripStatuses] = useState([]);
  const {user} = useApp();

  useEffect(() => {
    getConfig();
  }, []);

  console.log(user);

  const handleSubmit = async (data) => {
    let res = await request({
      method: "POST",
      url: "scheduling/add",
      body: { ...data, scheduled_by: parseInt(user.id) },
    });

    console.log("response: ", res);

    if (res === "error") return;
    navigate("/dashboard/vehicles/dispatch");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  async function getConfig() {
    let res = await request({
      method: "get",
      url: "config",
      show_loading: false,
      show_error: false,
    });
    if (res !== "error") {
      setTripStatuses(res?.trip_status || []);
    }
  }

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Dispatch & Schedule</h3>
      <div className="mt-4">
        <div className="flex gap-5 items-center max-md:flex-col">
          <SearchableSelect
            input={input}
            setInput={setInput}
            field="vehicle_id"
            validate={validate}
            url="vehicles/search-vehicle"
            setOptions={setVehicles}
          >
            {vehicles?.docs?.map((vehicle, i) => (
              <option value={vehicle?.id} key={i} label={vehicle.plate_no}>
                {vehicle?.plate_no}
              </option>
            ))}
          </SearchableSelect>
          <SearchableSelect
            input={input}
            setInput={setInput}
            field="route_id"
            validate={validate}
            url="routes/search"
            setOptions={setRoutes}
          >
            {routes?.docs?.map((route, i) => (
              <option value={route?.id} key={i} label={route.name}>
                {route?.name}
              </option>
            ))}
          </SearchableSelect>
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="departure_point"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="destination"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <DateInput
            input={input}
            setInput={setInput}
            field="departure_date"
            validate={validate}
          />
          <DateInput
            input={input}
            setInput={setInput}
            field="expected_arrival_date"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">

        {/* <SearchableSelect
            input={input}
            setInput={setInput}
            field="scheduled_by"
            validate={validate}
            url="members/search"
            setOptions={setMembers}
          >
            {members?.docs?.map((member, i) => (
              <option value={member?.id} key={i} label={member.name}>
                {member?.name}
              </option>
            ))}
          </SearchableSelect>
         */}
          {/* <SelectInput
            input={input}
            setInput={setInput}
            field="trip_status_id"
            validate={validate}
          >
            <option value="" disabled>
              Select Status
            </option>
            {tripStatuses.map((tripStatus, i) => (
              <option value={tripStatus.id} key={i}>
                {tripStatus.name}
              </option>
            ))}
          </SelectInput> */}
        </div>
      </div>

      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/vehicles/dispatch")}>Back</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default Add;
