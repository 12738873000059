import React, { useState } from "react";
import { TextInput, SelectInput, DateInput } from "../../Components/Inputs";
import init_member from "../init_member";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import { useEffect } from "react";

const Edit = () => {
  const [roles, setRoles] = useState([])
  const [input, setInput] = useState(init_member);
  const navigate = useNavigate();
  const request = useAxios();
  const { id } = useParams();

  useEffect(() => {
    getRoles();
  }, []);

  async function getRoles() {
    let res = await request({
      method: "get",
      url: "roles/all",
      show_loading: false,
      show_error: false,
    });
    if (res !== "error") {
      setRoles(res?.docs);
    }
  }

  

  async function handleUpdate(data) {
    let res = await request({
      method: "PUT",
      url: `members/update`,
      body: data,
      show_loading: true,
      show_error: true,
    });
    console.log(res)

    if (res !== "error") {
      navigate("/dashboard/members");
    }
  }


  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-2xl font-bold text-gray-700 mb-6">
        Edit Member
      </h3>
      <div className="mt-4">
        <TextInput input={input} setInput={setInput} field="name" />
        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput input={input} setInput={setInput} field="email" />
          <TextInput input={input} setInput={setInput} field="phone" />
        </div>
       
        <div className="flex gap-5 items-center max-md:flex-col">
        <SelectInput input={input} setInput={setInput} field="role">
          <option value="" disabled>
            Select Role
          </option>

          {roles.map((item,i)=>(
            <option key={i} value={item.id}>{item.name}</option>
          ))}

         
        </SelectInput>
        <SelectInput input={input} setInput={setInput} field="status">
          <option value="" disabled>
            Select Status
          </option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </SelectInput>
        </div>
        
      </div>
      <div className="mt-6 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/members")} >
          Back
        </Btn>
        <Btn onClick={handleUpdate} >
          Submit
        </Btn>
      </div>
    </div>
  );
};

export default Edit;
