import React, { useEffect, useState } from "react";
import { TextInput, SelectInput, DateInput } from "../../Components/Inputs"; 
import SearchableSelect from '../../Components/Inputs/SearchableSelect'
import Btn from "../../../../Components/Buttons/Btn";
import init_dispatch from "../init_dispatch";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import useForm from "../../Components/hooks/useForm";
import useApp from "../../../../Hooks/useApp";


const Edit = () => {
  const [input, setInput] = useState(init_dispatch);
  const navigate = useNavigate();
  const request = useAxios();
  const { id } = useParams();
  console.log("Dispatch ID from URL:", id);

  const [vehicles, setVehicles] = useState({});
  const [routes, setRoutes] = useState({});
  const [tripStatuses, setTripStatuses] = useState([]);

  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    getConfig();
    getDispatchDetails();
  }, []);

  const getDispatchDetails = async () => {
  
    let res = await request({
      method: "get",
      url: `scheduling/single-schedule`,
      params: {
        id: parseInt(id),
      }
    });
    console.log('respose: ', res)

    if (res === 'error') return;
    const inputCpy = {...init_dispatch};
    inputCpy.vehicle_id.value = res?.vehicle_id;
    inputCpy.route_id.value = res?.route_id;
    inputCpy.departure_point.value = res?.departure_point
    inputCpy.destination.value = res?.destination
    inputCpy.departure_date.value = res?.departure_date
    inputCpy.expected_arrival_date.value = res?.expected_arrival_date
    inputCpy.trip_status_id.value = res?.trip_status_id

    setInput(inputCpy);

  }
  console.log(input)

  const handleSubmit = async (data) => {
   
    let res = await request({
      method: "PUT",
      url: 'scheduling/update',
      body: { ...data, id: parseInt(id), vehicle_id: parseInt(input.vehicle_id.value), route_id: parseInt(input.route_id.value)
        },
        show_loading: true,
        loadingFunc: setIsLoading,
    });


    if (res !== "error") {
      navigate("/dashboard/vehicles/dispatch");
    }
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  async function getConfig() {
    let res = await request({
      method: "get",
      url: "config",
      show_loading: false,
      show_error: false,
    });
    if (res !== "error") {
      setTripStatuses(res?.trip_status || []);
    }
  }



  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Edit Dispatch</h3>
      <div className="mt-4">
        <div className="flex gap-5 items-center max-md:flex-col">
          <SearchableSelect
            input={input}
            setInput={setInput}
            field="vehicle_id"
            validate={validate}
            url='vehicles/search-vehicle'
            setOptions={setVehicles}
          >
            {vehicles?.docs?.map((vehicle, i) => (
              <option value={vehicle?.id} key={i} label={vehicle.plate_no}>
                {vehicle?.plate_no}
              </option>
            ))}
          </SearchableSelect>
          
          <SearchableSelect
            input={input}
            setInput={setInput}
            field="route_id"
            validate={validate}
            url='routes/search'
            setOptions={setRoutes}
          >
            {routes?.docs?.map((route, i) => (
              <option value={route?.id} key={i} label={route.name}>
                {route?.name}
              </option>
            ))}
          </SearchableSelect>
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="departure_point"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="destination"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <DateInput
            input={input}
            setInput={setInput}
            field="departure_date"
            validate={validate}
          />
          <DateInput
            input={input}
            setInput={setInput}
            field="expected_arrival_date"
            validate={validate}
          />
        </div>

        
      </div>

      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/vehicles/dispatch")}>Back</Btn>
        <Btn onClick={handleValidation} disabled={isLoading}>{isLoading ? 'updating...': 'Submit'}</Btn>
      </div>
    </div>
  );
};

export default Edit;



