import Input from "./Utils/Input";

function CheckBoxInput(props) {
  const { field = "", input, setInput, disabled = false, s = 1.3 } = props;

  if (typeof input !== "object") {
    console.error("input must be an object");
    return null;
  }

  function handleChange(e) {
    try {
      if (typeof field !== "string") {
        console.error("field must be a string");
        return;
      }

      if (typeof input !== "object" || Array.isArray(input)) {
        console.error("input must be an object");
        return;
      }

      const inputCpy = { ...input };

      const curentInput = inputCpy[field];
      let v = e.target.checked;
      curentInput.value = v;
      setInput(inputCpy);
    } catch (e) {
      console.error("Error updating checkbox: " + e.message);
    }
  }

  return (
    <div className="flex gap-x-2 items-center">
      <input
        type="checkbox"
        checked={
          typeof input[field].value === "boolean" ? input[field].value : false
        }
        onChange={handleChange}
        disabled={
          typeof input[field].disabled === "boolean"
            ? input[field].disabled
            : disabled
        }
        style={{
          height: `${s}em`,
          width: `${s}em`,
        }}
      />
      <span className=" text-lg font-semibold text-gray-600">
        {input[field]?.label}
      </span>
    </div>
  );
}

export default CheckBoxInput;
