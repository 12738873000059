import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import NavBtn from "../NavBtn";
import { FaChartBar, FaChevronDown } from "react-icons/fa";
import DropDown from "../../DropDown/DropDown";

const Reports = (props) => {
  const {
    exactPath = "/dashboard/reports",
    partialPath = "/reports",
    label = "Reports",
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location =  useLocation();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectReport = (report) => {
    navigate(report.to);
  };

  const dropdownItems = [
    { to: "/dashboard/reports/vehicle", label: "Vehicle report" },
    { to: "/dashboard/reports/fuel", label: "Fuel report" },
    { to: "/dashboard/reports/abnormaltraj", label: "Abnormal trajectory" },
    { to: "/dashboard/reports/fuel-analysis", label: "Fuel consumption analysis" },
    { to: "/dashboard/reports/trip", label: "Trip report" },
  ];

  return (
    <div className="relative">
      <div className="flex flex-col w-full">
        <div
          className="flex items-center cursor-pointer w-full"
          onClick={toggleDropdown}
        >
          <NavBtn
            label={<span className="flex items-center">
              {label}
              <FaChevronDown
                className={`ml-14 transition-transform ${
                  isOpen ? "rotate-180" : "rotate-0"
                }`}
              />
            </span>}
            exactPath={exactPath}
            partialPath={partialPath}
            Icon={FaChartBar}
          />
      
        </div>
        {isOpen && (
          
          <DropDown
            items={dropdownItems}
            onSelect={handleSelectReport}
            activePath={location.pathname}
          />
          
        )}
      </div>
    </div>
  );
};

export default Reports;

