import React from "react";
import EditTripReport from "../../../../Features/Forms/Reports/TripReport/Edit";

const Edit = () => {
  return (
    <div>
      <EditTripReport />
    </div>
  );
};

export default Edit;
