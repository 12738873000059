import React, { useEffect, useState } from "react";
import {
  TextInput,
  TextArea,
  DateInput,
  SelectInput,
 } from "../../Components/Inputs";
import init_vehicle from "../init_vehicle";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import useForm from "../../Components/hooks/useForm";

const Edit = () => {
  const [input, setInput] = useState(init_vehicle);
  const navigate = useNavigate();
  const request = useAxios()
  const {id} = useParams()

  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    getVehicles()
  },[]);

  const getVehicles = async () => {
    let res = await request({
      method: 'get',
      url: `vehicles/single-vehicle`,
      params: {
        id: parseInt(id),
      },
    });
    if (res === 'error') return;
    const inputCpy = {...init_vehicle};
    inputCpy.plate_no.value = res?.plate_no;
    inputCpy.make.value = res?.make;
    inputCpy.vin.value = res?.vin;
    inputCpy.fuel_capacity.value = res?.fuel_capacity;
    // inputCpy.plate_no.revenue = res?.revenue;
    inputCpy.mileage.value = res?.mileage;
    inputCpy.fleet_no.value = res?.fleet_no;
    inputCpy.trailer_no.value = res?.trailer_no;

    setInput(inputCpy);

  }

  const handleSubmit = async (data)=>{
    setIsLoading(true);
    let res = await request({
      method: 'PUT',
      url: 'vehicles/update',
      body: {
        id: parseInt(id),
        ...data,
      },
      show_loading: true,
      loadingFunc: setIsLoading,
    });
    if(res !== 'error'){
      navigate('/dashboard/vehicles/fleets')
    }
  }

  const {handleValidation, validate} = useForm({
    input,
    submit: handleSubmit,
  })

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-2xl font-bold text-gray-700 mb-6">
        Edit Vehicle
      </h3>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-6">
      <TextInput input={input} setInput={setInput} field="plate_no" validate={validate}/>
        <TextInput input={input} setInput={setInput} field="make" validate={validate}/>
        <TextInput input={input} setInput={setInput} field="vin" validate={validate}/>
        <TextInput input={input} setInput={setInput} field="fuel_capacity" validate={validate} />
        {/* <TextInput input={input} setInput={setInput} field="revenue" validate={validate} /> */}
        <TextInput input={input} setInput={setInput} field="mileage" validate={validate} />
        <TextInput input={input} setInput={setInput} field="fleet_no" validate={validate} />
        <TextInput input={input} setInput={setInput} field="trailer_no" validate={validate} />
      </div>
      <div className="mt-6 flex justify-between">
        <Btn
          onClick={() => navigate("/dashboard/vehicles/fleets")}
        >
          Back
        </Btn>
        <Btn
          onClick={handleValidation}
          disabled={isLoading}
        >
          {isLoading ? 'updating...': 'Submit'}
        </Btn>
      </div>
    </div>
  );
};

export default Edit;
