import { useContext } from "react";
import useApp from "./useApp";
import AppContext from "../Provider/AppContext";

function useHandleError() {
  const { requestErrorModal } = useContext(AppContext);

  return (e) => {
    if (e?.message === "Network Error") {
      requestErrorModal.handleShow({
        header: "Network Error",
        message: "Check your internet connection",
      });
      return;
    }

    if (e?.response?.data?.custom) {
      requestErrorModal.handleShow({
        header: "Client Error",
        message: e?.response?.data?.message,
      });
      return;
    }

    if (e?.status === 500) {
      requestErrorModal.handleShow({
        header: "Server Error",
        message: "Contact System Admin",
      });
      return;
    }

    requestErrorModal.handleShow({
      header: "Oops !",
      message: ["Try Again !!", "Contact system admin if it persists"],
    });
  };
}

export default useHandleError;
