import { useEffect, useRef } from "react";
import useAxios from "../../../Hooks/useAxios";

function useSearch(props) {
  const {
    search,
    searchUrl,
    setDocs = () => {},
    setIsLoading = () => {},
    setShowPagination = () => {},
    setIsFiltered,
  } = props;
  const request = useAxios();
  const abortControllerRef = useRef(null);

  useEffect(() => {
    handleSearch();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [search]);

  const handleSearch = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    try {
      if (typeof search !== "string" || search.length < 3 || !searchUrl) {
        return;
      }

      let res = await request({
        method: "GET",
        url: searchUrl,
        params: {
          search: search,
        },
        signal: abortControllerRef.current.signal,
        show_error: false,
        show_loading: false,
        loadingFunc: setIsLoading,
      });

      if (res === "error") {
        return;
      }
      setShowPagination(false);
      setIsFiltered(true);
      setDocs(res?.docs || []);
    } catch (e) {
      if (e.name === "AbortError") {
        console.log("Previous search request aborted");
      } else {
        console.error("Error doing search");
        console.error(e);
      }
    }
  };
}

export default useSearch;
