import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import useAxios from "../../../Hooks/useAxios";
import { convDateTime4 } from "../../../Util/Time";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const View = () => {
  const [fuelRecord, setFuelRecord] = useState(null);
  const { id } = useParams();
  const request = useAxios();

  useEffect(() => {
    fuelRecordData();
  }, []);

  const fuelRecordData = async () => {
    let res = await request({
      method: "get",
      url: "fuel/single-record",
      params: {
        id: parseInt(id),
      },
      show_loading: false,
    });

    if (res && res !== "error") {
      setFuelRecord(res);
    }
  };

  const chartData = {
    labels: ["Fuel Date"], 
    datasets: [
      {
        label: "Fuel Amount (litres)",
        data: fuelRecord ? [fuelRecord.fuel_amount] : [],
        fill: false,
        borderColor: "#4caf50",
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Fuel Consumption",
      },
    },
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Fuel Record Details</h1>
      <div className="grid grid-cols-2 gap-4">
        <div className="border p-4">
          <h2 className="text-xl font-semibold">Fuel Information</h2>
          {fuelRecord ? (
            <>
              <p><strong>Fuel Date:</strong> {convDateTime4(fuelRecord.fuel_date)}</p>
              <p><strong>Vehicle:</strong> {fuelRecord.vehicle_id}</p>
              <p><strong>Fueled By:</strong> {fuelRecord.fueled_by}</p>
              <p><strong>Trip:</strong> {fuelRecord.trip_id}</p>
              <p><strong>Cost:</strong> {fuelRecord.cost}</p>
              <p><strong>Fuel Amount:</strong> {fuelRecord.fuel_amount}</p>
              <p><strong>Price Per Litre:</strong> {fuelRecord.price_per_litre}</p>
              <p><strong>Filling Station:</strong> {fuelRecord.filling_station}</p>
              <p><strong>Receipt:</strong> {fuelRecord.nano_receipt}</p>
            </>
          ) : (
            <p>No fuel record found.</p>
          )}
        </div>

        {/* Chart for Fuel Consumption */}
        <div className="border p-4">
          <h2 className="text-xl font-semibold mb-2">Fuel Consumption Analysis</h2>
          {fuelRecord ? (
            <Line data={chartData} options={chartOptions} />
          ) : (
            <p>No data to analyze.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default View;
