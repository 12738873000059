import React from "react";
import Vehicles from "../../../Features/Tables/Vehicles";

const All = () => {
  return <div>
    <Vehicles />
  </div>;
};

export default All;

