import React, { useState } from "react";
import { TextInput, TextArea } from "../../Components/Inputs";
import useForm from "../../Components/hooks/useForm";
import init_request from "../init_request";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";

const Edit = () => {
  const [input, setInput] = useState(init_request);
  const navigate = useNavigate();
  return (
    <div className="w-[85%] m-auto shadow-2xl p-4 border-t rounded-md mt-12">
      <h3 className="text-xl text-center font-semibold text-primary">
        Edit Request
      </h3>
      <div className="mt-4">
        <TextInput input={input} setInput={setInput} field="item_name" />
        <TextInput input={input} setInput={setInput} field="quantity" />
        <TextArea input={input} setInput={setInput} field="description" />
        <div className="mt-4 flex justify-between">
          <Btn onClick={() => navigate("/dashboard/requests")}>Back</Btn>
          <Btn onClick={() => navigate("/dashboard/requests")}>Submit</Btn>
        </div>
      </div>
    </div>
  );
};

export default Edit;
