import React, { useState, useEffect } from "react";
import { LuBell } from "react-icons/lu";

function Notifications() {
  const [text, setText] = useState(0);
  return (
    <div className="p-2 rounded-full border">
      <div className="relative">
        <LuBell className="text-3xl" />
        <span className="absolute text-[12px] px-1 -top-1 -right-1 rounded-full text-white bg-red">
          {text}
        </span>
      </div>
    </div>
  );
}

export default Notifications;
