import React from "react";
import NavBtn from "../NavBtn";
import { FaGasPump } from "react-icons/fa6";

const FuelMgt = (props) => {
  const {
    exactPath = "/dashboard/fuel",
    to = "/dashboard/fuel",
    partialPath = "/fuel",
    label = "Fuel Management",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={FaGasPump}
    />
  );
};

export default FuelMgt;