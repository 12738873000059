const init_records = {
  driver: {
    value: "",
    label: "Driver",
    placeholder: "Enter Driver Name",
  },
  reg_number: {
    value: "",
    label: "Vehicle Reg",
    placeholder: "Enter Number Plate",
  },
  fuel_usage: {
    value: "",
    label: "Fuel Usage",
    placeholder: "Fuel Usage Data",
  },
  mantainance: {
    value: "",
    label: "Mantainance Date",
    placeholder: "Pick Date",
  },
  compliance: {
    value: "",
    label: "Compliance Status",
    placeholder: "Compliance Status",
  },
  reading: {
    value: "",
    label: "Odometer Reading",
    placeholder: "Enter Reading",
  },
  costs: {
    value: "",
    label: "Operating Costs",
    placeholder: "Operating Costs",
  },
  signal: {
    value: "",
    label: "Satelite Signal",
    placeholder: "satellite signal",
  },
  efficiency: {
    value: "",
    label: "Fuel Efficiency",
    placeholder: "KM/Litre",
  },
};

export default init_records;
