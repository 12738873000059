import { useState } from "react";

function useLoadingModal() {
  const [isLoading, setIsLoading] = useState(false);

  const loading = () => {
    setIsLoading(true);
  };

  const hide = () => {
    setIsLoading(false);
  };

  return { isLoading, setIsLoading, loading, hide };
}

export default useLoadingModal;
