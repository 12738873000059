import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AppContext from "../../Provider/AppContext";
import { IoChevronForward } from "react-icons/io5";

const Breadcrumb = () => {
  const location = useLocation();
  const { breadcrumbItems, setBreadcrumbItems } = useContext(AppContext);

  useEffect(() => {
    const pathname = location.pathname;
    const parts = pathname.split("/").filter((part) => part);
    const newBreadcrumbItems = parts.map((part, index) => ({
      label: part.replace("-", " "),
      to: `/${parts.slice(0, index + 1).join("/")}`,
    }));

    setBreadcrumbItems([...newBreadcrumbItems.slice(1)]);
  }, [location.pathname, setBreadcrumbItems]);

  return (
    <div className="flex items-center text-gray-600 bg-white -ml-2 rounded-md font-poppins">
      {breadcrumbItems.map((item, index) => (
        <div key={index} className="flex items-center font-bold">
          {index > 0 && (
            <IoChevronForward className="mx-2 text-xl text-gray-300" />
          )}
          {item.to ? (
            <Link
              to={item.to}
              className={`text-2xl ${
                index === breadcrumbItems.length - 1
                  ? "text-primary"
                  : "text-gray-300"
              }`}
            >
              {item.label.charAt(0).toUpperCase() + item.label.slice(1)}
            </Link>
          ) : (
            <span>{item.label}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumb;
