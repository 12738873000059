import React from "react";
import EditOrder from "../../../Features/Forms/POs/Edit";

const Edit = () => {
  return (
    <div>
      <EditOrder />
    </div>
  );
};

export default Edit;
