import { useContext } from "react";
import { Thead, THT, THTS } from "../../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Driver" field="driver" setOrder={setOrder} />
      <THTS txt="Vehicle Reg" field="reg_number" setOrder={setOrder} />
      <THTS txt="Fuel Usage" field="fuel_usage" setOrder={setOrder} />
      <THTS txt="Mantainance Date" field="mantainace_date" setOrder={setOrder} />
      <THTS txt="Compliance Status" field="compliance" setOrder={setOrder} />
      <THTS txt="Odometer Reading" field="reading" setOrder={setOrder} />
      <THTS txt="Operating Costs" field="costs" setOrder={setOrder} />
      <THTS txt="Satelite Signal" field="signal" setOrder={setOrder} />
      <THTS txt="Fuel Efficiency" field="efficiency" setOrder={setOrder} />
      <THT txt="Actions" />
    </Thead>
  );
}

export default TableHead;
