import React, { useEffect, useState } from "react";
import { TextInput, SelectInput, DateInput } from "../../../Components/Inputs";
import Btn from "../../../../../Components/Buttons/Btn";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../Hooks/useAxios";
import useForm from "../../../Components/hooks/useForm";
import init_records from "../init_records";

const Edit = () => {
  const { id } = useParams();
  const [input, setInput] = useState(init_records);
  const navigate = useNavigate();
  const request = useAxios();

  useEffect(() => {
    getFuelDetails();
  }, []);

  async function getFuelDetails() {
    let res = await request({
      method: "get",
      url: `fuel/${id}`,
      show_loading: false,
      show_error: false,
    });
    if (res !== "error") {
      setInput(res?.doc);
    }
  }

  const handleSubmit = async (data) => {
    let res = await request({
      method: "PUT", 
      url: `fuel/update/${id}`,
      body: data,
    });
    if (res === "error") return;
    navigate("/dashboard/fuel");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Edit Fuel Record</h3>
      <div className="mt-4">

        <div className="flex gap-5 items-center max-md:flex-col">
         
          <TextInput
            input={input}
            setInput={setInput}
            field="reg_number"
            validate={validate}
            label="Vehicle Reg"
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="driver"
            validate={validate}
            label="Driver"
          />
        
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="fuel_usage"
            validate={validate}
            label="Fuel Usage"
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="reading"
            validate={validate}
            label="Odometer Reading"
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="costs"
            validate={validate}
            label="Operating Costs"
          />
          <SelectInput
          input={input}
          setInput={setInput}
          field="compliance"
          validate={validate}
          mt-4
          mb-4>

            <option value="disabled">select status</option>
            <option value="compliant">compliant</option>
            <option value="non-compliant">non compliant</option>

          </SelectInput>
          
            
            
         
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="efficiency"
            validate={validate}
            label="Fuel Efficiency (Km/L)"
          />
        </div>
        
      </div>
      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/fuel")}>Back</Btn>
        <Btn onClick={handleValidation}>Update</Btn>
      </div>
    </div>
  );
};

export default Edit;

