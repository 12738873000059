import React from "react";
import NavBtn from "../NavBtn";
import { FaMapLocationDot } from "react-icons/fa6";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import DropDown from "../../DropDown/DropDown";

const Monitoring = (props) => {
  const {
    exactPath = "/dashboard/monitoring",
    partialPath = "/monitoring",
    label = "Monitoring",
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMonitoring = (monitoring) => {
    navigate(monitoring.to);
  };

  const dropdownItems = [
    { to: "/dashboard/monitoring", label: "Monitoring" },
    { to: "/dashboard/monitoring/viewtracks", label: "View Tracks" },
    { to: "/dashboard/monitoring/trackassets", label: "Track Assets" },
  ];

  return (
    <div className="relative">
      <div className="flex flex-col w-full">
        <div
          className="flex items-center cursor-pointer w-full"
          onClick={toggleDropdown}
        >
          <NavBtn
            label={
              <span className="flex items-center">
                {label}
                <FaChevronDown
                  className={`ml-14 transition-transform ${
                    isOpen ? "rotate-180" : "rotate-0"
                  }`}
                />
              </span>
            }
            exactPath={exactPath}
            partialPath={partialPath}
            Icon={FaMapLocationDot}
          />
        </div>
        { isOpen &&  <DropDown
          items={dropdownItems}
          onSelect={handleMonitoring}
          activePath={location.pathname}
          />}
      </div>
    </div>
  );
};

export default Monitoring;
