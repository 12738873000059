import React, { useState } from "react";
import { TextInput, DateInput } from "../../../Components/Inputs";
import init_records from "../init_records";
import Btn from "../../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../../Hooks/useAxios";
import useForm from "../../../Components/hooks/useForm";

const Add = () => {
  const [input, setInput] = useState(init_records);

  const navigate = useNavigate();
  const request = useAxios();

  const handleSubmit = async (data) => {
    let res = await request({
      method: "POST",
      url: "trip/add",
      body: data,
    });
    if (res === "error") return;
    navigate("/dashboard/trip");
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Add Trip Record</h3>
      <div className="mt-4">
        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="driver"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="reg_number"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <DateInput
            input={input}
            setInput={setInput}
            field="date"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="start_location"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="end_location"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="distance"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="consumption"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="duration"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="cargo_details"
            validate={validate}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/trip")}>Back</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default Add;

