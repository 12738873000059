import { useEffect } from "react";
import BasicModal from "./BasicModal";
import Lottie from "lottie-react";

import botAnimation from "../../Assets/animations/success.json";

import useDevice from "../../Hooks/useDevice";

function RequestSuccessModal({ requestSuccessModal }) {
  const {
    showRequestSuccessModal,
    setShowRequestSuccessModal,
    requestSuccessMessage,
    requestSuccessHeader,
    requestSuccessMessageTimeout,
  } = requestSuccessModal;

  const device = useDevice();

  useEffect(() => {
    if (showRequestSuccessModal === false) {
      return;
    }

    const timeOut = setTimeout(() => {
      setShowRequestSuccessModal(false);
    }, requestSuccessMessageTimeout);

    return () => {
      clearTimeout(timeOut);
    };
  }, [showRequestSuccessModal]);

  return (
    <BasicModal
      bg="primary"
      header={requestSuccessHeader}
      showModal={showRequestSuccessModal}
      setShowModal={setShowRequestSuccessModal}
    >
      <div
        className="p-2 w-full flex flex-wrap m-auto items-center gap-x-4 "
        style={{ width: device === "sm" ? "auto" : "40em" }}
      >
        <div className=" p-2" style={{ width: "10em" }}>
          <Lottie animationData={botAnimation} />
        </div>
        <SuccessMessage SuccessMessage={requestSuccessMessage} />
      </div>
    </BasicModal>
  );
}

function SuccessMessage(props) {
  const { SuccessMessage } = props;
  if (Array.isArray(SuccessMessage)) {
    return (
      <div className=" flex flex-col gap-y-2">
        {SuccessMessage.map((message) => {
          return <span className="text-xl">{message}</span>;
        })}
      </div>
    );
  }

  return (
    <div className="whitespace-normal w-fit text-xl" style={{}}>
      {SuccessMessage}
    </div>
  );
}

export default RequestSuccessModal;
