import React, { useState } from "react";
import AddVehicle from '../../../../Features/Tables/SelectedVehicles'
import Btn from "../../../../Components/Buttons/Btn";
import useAxios from "../../../../Hooks/useAxios";
import useApp from "../../../../Hooks/useApp";
import { useNavigate, useParams } from "react-router-dom";

const Add = () => {
  const request = useAxios();
//   const { user } = useApp();
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedVehicle, setSelectedVehicle] = useState([]);

  async function handleSubmit() {
    let res = await request({
      method: "post",
      url: "/vehicles/add",
      body: {
        id: parseInt(id),
        vehicle_id: selectedVehicle,
      },
    });

    if (res !== "error") {
      navigate(`/dashboard/buses/view/${id}`);
    }
  }

  return (
    <div>
      <AddVehicle
        selectedVehicle={selectedVehicle}
        setSelectedVehicle={setSelectedVehicle}
      />
      <div className="mt-5">
        <Btn size="block" onClick={handleSubmit}>
          Attach Device
        </Btn>
      </div>
    </div>
  );
};

export default Add;
