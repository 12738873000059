import React from "react";

import useDevice from "../../../../Hooks/useDevice";

function Thead(props) {
  const { children } = props;
  const device = useDevice();
  if (device === "sm") {
    return;
  }
  return (
    <thead className="bg-light_grey py-5 border-t-2 border-b-2">
      <tr className="text-left p-3">{children}</tr>
    </thead>
  );
}

export default Thead;
