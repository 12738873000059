import React from "react";
import AddLogistic from '../../../Features/Forms/Logistics/Add'

const Add = () => {
  return <div>
    <AddLogistic />
  </div>;
};

export default Add;
