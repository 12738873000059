import React from "react";
import EditVehicleReport from "../../../../Features/Forms/Reports/VehicleReport/Edit";

const Edit = () => {
  return (
    <div>
      <EditVehicleReport />
    </div>
  );
};

export default Edit;
