import { useState, useEffect } from "react";

function useForm({ submit = () => {}, input }) {
  const [validate, setValidate] = useState(0);

  useEffect(() => {
    if (validate <= 0) return;
    callSubmit();
  }, [validate]);

  function handleValidation() {
    setValidate((c) => c + 1);
  }

  function callSubmit() {
    if (!input) {
      submit();
      console.error("Invalid input for validation");
      return;
    }

    if (typeof input !== "object") {
      submit();
      console.error("Input must be an object");
      return;
    }

    if (Array.isArray(input)) {
      submit();
      console.error("Input is an array. must be an object");
      return;
    }

    let keys = Object.keys(input);
    let data = {};

    for (let key of keys) {
      const singleInput = input[key];

      data[key] = singleInput.value;

      if (!singleInput) {
        continue;
      }

      if (typeof singleInput?.required !== "boolean") {
        continue;
      }

      if (singleInput.required) {
        if (singleInput?.error === true) {
          return;
        }
      }
    }
    setValidate(0);
    submit(data);
  }

  return { handleValidation, validate };
}

export default useForm;
