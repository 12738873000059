import React from "react";
import NavBtn from "../NavBtn";
import { FaBuilding } from "react-icons/fa"

const Settings = (props) => {
  const {
    exactPath = "/dashboard/company-details",
    to = "/dashboard/company-details",
    partialPath = "/company-details",
    label = "Company",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={FaBuilding}
    />
  );
};

export default Settings;