import React from "react";
import EditFuelReport from "../../../../Features/Forms/Reports/FuelReport/Edit";

const Edit = () => {
  return (
    <div>
      <EditFuelReport />
    </div>
  );
};

export default Edit;
