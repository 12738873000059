import React from "react";
import FuelReports from '../../../../Features/Tables/Reports/FuelReport'

const All = () => {
  return <div>
    <FuelReports />
  </div>;
};

export default All;
