import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt=" PURCHASE ORDER ID" field="purchase_order_id" setOrder={setOrder} />
      <THTS txt="Item Name" field="item_name" setOrder={setOrder} />
      <THTS txt="Quantity" field="quantity" setOrder={setOrder} />
      <THTS txt="Total Cost" field="total_cost" setOrder={setOrder} />
      <THTS txt="Cost Per Item" field="cost_per_item" setOrder={setOrder} />
      <THTS txt="Warehouse" field="warehouse_id" setOrder={setOrder} />

      <THT txt="Actions" />
    </Thead>
  );
}

export default TableHead;
