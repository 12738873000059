import React, { useState } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { useEffect } from "react";
import useAxios from "../../Hooks/useAxios";

const FleetDashboard = () => {
  const request = useAxios();

  const [image, setImage] = useState("");

  const fuelData = [
    { name: "Vehicle 1", fuelUsage: 75 },
    { name: "Vehicle 2", fuelUsage: 60 },
    { name: "Vehicle 3", fuelUsage: 90 },
  ];

  const distanceData = [
    { name: "Jan", distance: 400 },
    { name: "Feb", distance: 800 },
    { name: "Mar", distance: 600 },
    { name: "Apr", distance: 1200 },
    { name: "May", distance: 900 },
  ];

  const statusData = [
    { name: "In Transit", value: 3 },
    { name: "Available", value: 5 },
    { name: "Maintenance", value: 2 },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

  const vehicles = [
    {
      id: 1,
      name: "Vehicle 1",
      fuelUsage: 75,
      distanceTraveled: 1500,
      status: "In Transit",
    },
    {
      id: 2,
      name: "Vehicle 2",
      fuelUsage: 60,
      distanceTraveled: 1250,
      status: "Available",
    },
    {
      id: 3,
      name: "Vehicle 3",
      fuelUsage: 90,
      distanceTraveled: 1800,
      status: "Maintenance",
    },
  ];

  const dispatches = [
    {
      id: 1,
      vehicle: "Vehicle 1",
      time: "09:00 AM",
      destination: "Warehouse A",
    },
    {
      id: 2,
      vehicle: "Vehicle 2",
      time: "10:30 AM",
      destination: "Client Location B",
    },
  ];

  return (
    <div className="p-8 bg-gray-100">
      <h1 className="text-4xl font-bold mb-8">Summary</h1>

      {/* Fuel Management */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-2">Total Fuel Usage</h2>
          <p className="text-2xl font-bold">2250 L</p>
        </div>
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-2">Fuel Cost</h2>
          <p className="text-2xl font-bold">$2,500</p>
        </div>
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-2">Scheduled Maintenance</h2>
          <p className="text-2xl font-bold">2 Vehicles</p>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-2">Upcoming Dispatches</h2>
          <p className="text-2xl font-bold">5 Dispatches</p>
        </div>
      </div>

      <div className="mb-8 bg-white p-5">
        <h2 className="text-2xl font-semibold mb-4">Fuel Usage Analytics</h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={fuelData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="fuelUsage" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Vehicle Tracking */}
      <div className="mb-8 bg-white p-5">
        <h2 className="text-2xl font-semibold mb-4">
          Vehicle Status Breakdown
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={statusData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={({ name, percent }) =>
                `${name}: ${(percent * 100).toFixed(0)}%`
              }
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
            >
              {statusData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>

      {/* Additional Metrics */}
      <div className="mb-8 bg-white p-5">
        <h2 className="text-2xl font-semibold mb-4">
          Distance Traveled Over Time
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={distanceData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="distance" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div>
        <img src={image} alt="w-48" />
      </div>
    </div>
  );
};

export default FleetDashboard;
