import React from "react";
import RolesTable from "../../../Features/Tables/Roles";

const All = () => {
  return (
    <div>
      <RolesTable />
    </div>
  );
};

export default All;
