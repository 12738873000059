import React from "react";
import AddFuelReport from '../../../../Features/Forms/Reports/FuelReport/Add'

const Add = () => {
  return <div>
    <AddFuelReport />
  </div>;
};

export default Add;
