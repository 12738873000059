import React, { useState } from "react";

const AssignDeviceModal = ({ showAssignModal, setShowAssignModal, devices, vehicles, handleAssign }) => {
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");

  const handleAssignClick = () => {
    if (selectedDevice && selectedVehicle) {
      handleAssign(selectedDevice, selectedVehicle);
      setShowAssignModal(false);
    } else {
      alert("Please select both a device and a vehicle.");
    }
  };

  if (!showAssignModal) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-md mx-auto rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-semibold mb-4">Assign Device to Vehicle</h2>

        <div className="mb-4">
          <label className="block text-gray-700">Select Device</label>
          <select
            value={selectedDevice}
            onChange={(e) => setSelectedDevice(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">-- Select Device --</option>
            {devices.map((device) => (
              <option key={device.id} value={device.id}>
                {device.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Select Vehicle</label>
          <select
            value={selectedVehicle}
            onChange={(e) => setSelectedVehicle(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">-- Select Vehicle --</option>
            {vehicles.map((vehicle) => (
              <option key={vehicle.id} value={vehicle.id}>
                {vehicle.plate_no}
              </option>
            ))}
          </select>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setShowAssignModal(false)}
            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleAssignClick}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Assign
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignDeviceModal;

