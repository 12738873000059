const init_role = {
  name: {
    value: "",
    label: "Role Name",
    placeholder: "Enter role name",
  },
  description: {
    value: "",
    label: "Role Description",
    placeholder: "Enter role description",
  },
};

export default init_role;
