import { TR, TDT } from "../../components";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";
import { convDate3 } from "../../../../Util/Time";

function TableRow({ doc }) {
  const navigate = useNavigate();
  const { setShowDeleteModal, setSystemId } = useContext(TableContext);

  const handleDelete = () => {
    setSystemId(doc?.id);
    setShowDeleteModal(true);
  };

  return (
 
    
        <TR>
          <TDT name="Routes" txt={doc?.routes?.name || ""} />
          <TDT name="Description" txt={doc?.routes?.description || ""} />
          <TDT name="Stops" txt={doc?.routes?.no_of_stops || ""} />
          <TDT name="Expected Arrival Date" txt={convDate3(doc?.expected_arrival_date) || ""} />
          <TDT name="Actual Arrival Date" txt={convDate3(doc?.actual_arrival_date) || ""} />
          {/* <TDT>
            <button onClick={() => navigate(`/edit/${doc.id}`)}>
              <FaRegEdit /> Edit
            </button>
            <button onClick={handleDelete}>
              <RiDeleteBin5Line /> Delete
            </button>
          </TDT> */}
        </TR>
    
  );
}


export default TableRow;
