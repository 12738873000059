import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Trip" field="trip_id" setOrder={setOrder} />
      <THTS txt="Fuel Date" field="fuel_date" setOrder={setOrder} />
      <THTS txt="Cost" field="cost" setOrder={setOrder} />
      <THTS txt="Price Per Litre" field="price_per_litre" setOrder={setOrder} />
      <THTS txt="Fuel Amount" field="fuel_amount" setOrder={setOrder} />
      <THTS txt="Filling Station" field="filling_station" setOrder={setOrder} />
      <THTS txt="Receipt" field="documents" setOrder={setOrder} />
      {/* <THT txt="Actions" /> */}
    </Thead>
  );
}

export default TableHead;
