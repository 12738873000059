import React from "react";
import NavBtn from "../NavBtn";
import { HiOutlineInboxIn } from "react-icons/hi";

const Requests = (props) => {
  const {
    exactPath = "/dashboard/requests",
    to = "/dashboard/requests",
    partialPath = "/requests",
    label = "Requests",
  } = props;

  return (
    <NavBtn
      label={label}
      exactPath={exactPath}
      partialPath={partialPath}
      to={to}
      Icon={HiOutlineInboxIn}
    />
  );
};

export default Requests;