import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Device Name" field="name" setOrder={setOrder} />
      <THTS txt="Device ID" field="device_id" setOrder={setOrder} />
      <THTS txt="Mac Address" field="mac_address" setOrder={setOrder} />
      <THTS txt="Sim Card No Date" field="sim_card_no" setOrder={setOrder} />
      <THTS txt="Imei" field="imei" setOrder={setOrder} />
      <THTS txt="Device Type" field="device_type" setOrder={setOrder} />
      
      <THT txt="Actions" />
    </Thead>
  );
}

export default TableHead;
