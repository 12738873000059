import { useEffect, useState } from "react";
import transform from "../../utils/transform";

import validationObj from "../../utils/validations";

function useInput({
  input = {},
  setInput = {},
  field = "",
  validate,
  exValidate,
  customHandleChange,
}) {
  const [u, setU] = useState(0);

  useEffect(() => {
    if (!validate) {
      return;
    }
    if (validate > 0) {
      handleValidation();
    }
  }, [validate]);

  useEffect(() => {
    if (u == 0) return;

    handleValidation();
  }, [u]);

  const handleChange = (e) => {
    try {
      if (customHandleChange && typeof customHandleChange === "function") {
        customHandleChange(e);
        return;
      }

      const v = e.target.value;
      updateInput(v);
    } catch (e) {
      console.error("failed to handle change");
      console.error(e?.message || e);
    } finally {
      setU((c) => c + 1);
    }
  };

  const updateInput = (v) => {
    try {
      if (typeof field !== "string") {
        console.error("field must be a string");
        return;
      }

      if (typeof input !== "object" || Array.isArray(input)) {
        console.error("input must be an object");
        return;
      }

      const inputCpy = { ...input };
      const trans = inputCpy[field]?.transform;

      const curentInput = inputCpy[field];

      if (typeof curentInput !== "object" || Array.isArray(curentInput)) {
        console.error("curent input must be an object");
        return;
      }

      if (trans) {
        let tf = transform[trans];
        if (tf) {
          v = tf(v);
        }
      }

      curentInput.value = v;

      setInput(inputCpy);
    } catch (e) {
      console.error(e);
    }
  };

  const handleValidation = () => {
    try {
      if (typeof field !== "string") {
        console.error("field must be a string");
        return;
      }

      if (typeof input !== "object" || Array.isArray(input)) {
        console.error("input must be an object");
        return;
      }

      const inputCpy = { ...input };

      const curentInput = inputCpy[field];

      let required = curentInput.required;

      if (!required) {
        return;
      }

      let validations = curentInput?.validation;
      doValidations(validations);

      if (exValidate) {
        doValidations(exValidate);
      }
    } catch (e) {
      console.error("Handle validation error");
      console.error(e.message);
    }
  };

  function doValidations(validations) {
    try {
      const inputCpy = { ...input };

      const curentInput = inputCpy[field];

      let required = curentInput.required;

      if (!required) {
        return;
      }

      if (!validations) {
        console.error("no validations");
        return;
      }

      if (typeof validations !== "object") {
        console.error("type of validaions not object");
        return;
      }

      let keys = Object.keys(validations);

      const v = input[field]?.value;

      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];

        let validator = validations[key];
        let fn = validationObj[key];
        if (!fn) {
          continue;
        }

        let fn_result = fn(v, validator?.value);

        if (key === "matchInput") {
          fn_result = matchInput(v, validator?.value);
        }

        if (fn_result) {
          curentInput.error = true;
          curentInput.e_message = validator?.message || "";
          setInput(inputCpy);
          return;
        }
        curentInput.error = false;
        setInput(inputCpy);
      }
    } catch (e) {
      console.error("Handle validation error");
      console.error(e.message);
    }
  }

  const matchInput = (v, value) => {
    try {
      if (!v) return;

      let toMatch = input[value]?.value;

      if (!toMatch) {
        return false;
      }

      if (toMatch?.toString() === v?.toString()) {
        return false;
      }

      return true;
    } catch (e) {
      console.error("Match validation error");
      return false;
    }
  };

  return { handleChange, updateInput, handleValidation };
}

export default useInput;
