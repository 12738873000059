import React from 'react'

const Badge = (props) => {
    const {txt = "", bg = "" } = props
  return (
    <div className={`rounded-2xl w-fit px-4 bg-${bg}`}>
        <p className='text-white text-sm'>{txt}</p>
    </div>
  )
}

export default Badge