import TableHead from "./Table/TableHead";

import useTable from "./Provider/useTable";
import TableRow from "./Table/TableRow";

import { Table, TBody } from "../components";

import { useNavigate } from "react-router-dom";
import TableContext from "./Provider/TableContext";

import CancelModal from "./Table/CancelModal";

function PurchaseOrders() {
  const {
    limit,
    setLimit,
    docs,
    setDocs,
    isLoading,
    setIsLoading,
    isFiltered,
    setIsFiltered,
    pagination,
    genders,
    dobRange,
    setDobRange,
    selectedGenders,
    setSelectedGenders,
    doaRange,
    setDoaRange,
    setOrder,
    clearFilters,
    refetchDocs,
    showApproveModal,
    setShowApproveModal,
    orderId,
    setOrderId,
    handleDelete,
    handleApprove,
    showCancelModal,
    setShowCancelModal,
  } = useTable();

  const navigate = useNavigate();

  return (
    <>
      <TableContext.Provider
        value={{
          limit,
          setLimit,
          docs,
          setDocs,
          isLoading,
          setIsLoading,
          isFiltered,
          setIsFiltered,
          pagination,
          genders,
          dobRange,
          setDobRange,
          selectedGenders,
          setSelectedGenders,
          doaRange,
          setDoaRange,
          setOrder,
          clearFilters,
          refetchDocs,
          showApproveModal,
          setShowApproveModal,
          orderId,
          setOrderId,
          handleDelete,
          handleApprove,
          showCancelModal,
          setShowCancelModal,
        }}
      >
        <Table
          label="Inventory"
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setDocs={setDocs}
          isFiltered={isFiltered}
          handleAdd={() => navigate("add")}
          setIsFiltered={setIsFiltered}
          handleRemoveFilters={clearFilters}
          pagination={pagination}
          url="roles/search"
        >
          <TableHead setOrder={setOrder} />
          <TBody>
            {docs.map((doc, i) => {
              return <TableRow key={doc?.id || i} doc={doc} />;
            })}
          </TBody>
        </Table>
      </TableContext.Provider>

      {showCancelModal && (
        <CancelModal
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
}

export default PurchaseOrders;
