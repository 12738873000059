import React, { useEffect, useState } from "react";
import Avatar from "./Avatar";
import Notifications from "./Notification";
import { IoChevronDown } from "react-icons/io5";
import useApp from "../../Hooks/useApp";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../BreadCrumb";
import { HiOutlineLogout } from "react-icons/hi";
import { clearEncryptedData } from "../../Util/store";
import Settings from "./Settings";

function Topbar() {
  const [openChev, setOpenChev] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setSideBarOpen, sideBarOpen, device } = useApp();

  const toggleChev = () => {
    setOpenChev(!openChev);
  };

  useEffect(() => {
    if (device === "sm") {
      setSideBarOpen(false);
    } else {
      setSideBarOpen(true);
    }
  }, [device]);

  const handleLogout = () => {
    navigate("/");
    clearEncryptedData("*");
  };

  return (
    <div className="w-full py-4 px-4 flex max-md:flex-wrap max-md:gap-4 justify-between items-center pr-4">
      {!sideBarOpen && (
        <div
          className="p-2 cursor-pointer rounded-md border focus:outline-none focus:bg-gray-700 md:hidden"
          onClick={() => setSideBarOpen(true)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </div>
      )}

      <div className="max-md:hidden">
        {location.pathname === "/dashboard" ? (
          <p className="text-primary text-2xl font-semibold">Dashboard</p>
        ) : (
          <Breadcrumb />
        )}
      </div>

      <div className="flex max-md:flex-col gap-4 max-md:justify-center max-md:items-center">
        <div className="flex gap-6 max-md:justify-center max-md:w-full max-md:items-center max-sm:justify-center max-sm:w-full max-sm:items-center">
          <Settings />
          <Notifications />
          <div className="flex gap-3 items-center relative">
            <Avatar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
