import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Routes" field="name" setOrder={setOrder} />
      <THTS txt="Description" field="description" setOrder={setOrder} />
      <THTS txt="Stops" field="no_of_stops" setOrder={setOrder} />
      <THTS txt="Expected Arrival Date" field="expected_arrival_date" setOrder={setOrder} />
      <THTS txt="Actual Arrival Date" field="actual_arrival_date" setOrder={setOrder} />

      {/* <THT txt="Actions" /> */}
    </Thead>
  );
}

export default TableHead;
