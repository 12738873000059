import React, { useState } from "react";
import { TextInput, TextArea, useForm } from "../../Components/Inputs";
import init_role from "../init_role";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";

const Add = () => {
  const [input, setInput] = useState(init_role);
  const navigate = useNavigate();
  const request = useAxios();

  async function handleAdd(data) {
    let res = await request({
      method: "POST",
      url: "roles/add",
      body: data,
    });

    if (res !== "error") {
      navigate("/dashboard/roles");
    }
  }

  const { handleValidation, validate } = useForm({
    input,
    submit: handleAdd,
  });

  return (
    <div className="bg-white p-4 mt-6 w-[90%] m-auto shadow-2xl">
      <h3 className="text-center font-semibold text-xl text-primary">
        Add Role
      </h3>
      <div className="mt-5">
        <div className="w-full">
          <TextInput input={input} setInput={setInput} field="name" />
          <TextArea input={input} setInput={setInput} field="description" />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <Btn onClick={() => navigate("/dashboard/roles")}>Cancel</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default Add;
