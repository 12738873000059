import React from "react";
import AddFuelRecord from '../../../Features/Forms/FuelManagement/Add'

const Add = () => {
  return <div>
    <AddFuelRecord />
  </div>;
};

export default Add;
