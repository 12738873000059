/* eslint-disable react/prop-types */
import React, { useState } from "react";
import useAxios from "../../../../Hooks/useAxios";

import Input from "./Utils/Input";
import inputClass from "../../utils/input.style";
import useInput from "../hooks/useInput";

const SearchableSelect = (props) => {
  const {
    field = "",
    input,
    setInput,
    mt,
    mb,
    validate,
    exValidate,
    children,
    url = "",
    setOptions = () => {},
  } = props;

  const [searchTerm, setSearchTerm] = useState(input[field]?.value || "");
  const [isOpen, setIsOpen] = useState(false);

  const request = useAxios();

  const { handleChange } = useInput({
    input,
    setInput,
    field,
    validate,
    exValidate,
  });

  if (typeof input !== "object") {
    console.error("input must be an object");
    return null;
  }

  const handleSearchChange = async (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
    const res = await request({
      url,
      method: "GET",
      params: {
        search: e.target.value,
      },
      show_loading: false,
    });
    setOptions(res);
  };

  const handleOptionClick = (value, label) => {
    handleChange({ target: { value } });
    setSearchTerm(label);
    setIsOpen(false);
  };

  return (
    <Input mt={mt} mb={mb} input={input[field] || null} validate={validate}>
      <div className="relative">
        <input
          type="text"
          className={`${inputClass.base} py-2 bg-transparent capitalize`}
          value={searchTerm}
          placeholder={input[field]?.placeholder || ""}
          onChange={handleSearchChange}
          disabled={input[field]?.disabled || false}
        />
        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 ">
            {children?.length > 0 ? (
              children.map((child) => (
                <div
                  key={child.props.value}
                  className="p-2 cursor-pointer hover:bg-gray-200"
                  onClick={() =>
                    handleOptionClick(child.props.value, child.props.label)
                  }
                >
                  {child.props.children}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500">No options found</div>
            )}
          </div>
        )}
      </div>
    </Input>
  );
};

export default SearchableSelect;
