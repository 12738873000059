import { useState } from "react";
import Input from "./Utils/Input";

import inputClass from "../../utils/input.style";

import useInput from "../hooks/useInput";

import { FaEye, FaEyeSlash } from "react-icons/fa";

function PasswordInput(props) {
  const { field = "", input, setInput, mt, mb, validate } = props;

  const [type, setType] = useState("password");

  const [isVisible, setIsVisible] = useState(false);

  const { handleChange } = useInput({ input, setInput, field, validate });

  if (typeof input !== "object") {
    console.error("input must be an object");
    return null;
  }

  return (
    <Input mt={mt} mb={mb} input={input[field] || null} validate={validate}>
      <div className=" h-full flex w-full items-center">
        <input
          className={`${inputClass.base} flex-1`}
          value={input[field]?.value || ""}
          placeholder={input[field]?.placeholder || ""}
          onChange={handleChange}
          disabled={input[field]?.disabled || false}
          type={isVisible ? "text" : "password"}
        />
        <span
          className=" cursor-pointer active:opacity-50 mr-4 text-lg"
          onClick={() => setIsVisible((c) => !c)}
        >
          {isVisible ? <FaEye /> : <FaEyeSlash />}
        </span>
      </div>
    </Input>
  );
}

export default PasswordInput;
