import React from "react";
import logoBig from "../../Assets/images/blue_logo.png"

function LogoSection() {
  return (
    <div className="cursor-pointer active:opacity-50 flex justify-center">
      <img src={logoBig} alt="logo" style={{ width: "12em" }} />
    </div>
  );
}

export default LogoSection;
