import { LuLayoutDashboard } from "react-icons/lu";

function LgScreenTabs(props) {
  const {
    list = [
      { id: 1, value: "Tab 1" },
      { id: 2, value: "Tab 2" },
      { id: 3, value: "Tab 3" },
    ],
    active = 1,
    setActive = () => {},
  } = props;

  return (
    <div className="w-full">
      <div className="flex gap-x-2 bg-transparent">
        {list.map((item, i) => (
          <RenderTab
            key={i}
            id={item?.id}
            name={item?.value}
            icon={item?.icon}
            active={active}
            setActive={setActive}
          />
        ))}
      </div>
      <div className="bg-primary w-full" style={{ height: 2 }}></div>
    </div>
  );
}

function RenderTab(props) {
  const { id = 1, name = "Tab Text", icon, active, setActive } = props;

  if (id === active) {
    return <ActiveTab id={id} name={name} icon={icon} setActive={setActive} />;
  }

  return <SingleTab id={id} name={name} icon={icon} setActive={setActive} />;
}

function SingleTab(props) {
  const { id, icon: IconComponent, name, setActive = () => {} } = props;

  return (
    <div
      onClick={() => setActive(id)}
      className="flex-1 border-primary rounded-t-md flex items-center gap-x-2 hover:text-primary py-1 px-2 cursor-pointer bg-cream-white"
    >
      <span>
        {IconComponent ? <IconComponent className="text-xl" /> : <LuLayoutDashboard className="text-xl" />}
      </span>
      <span
        style={{ color: "rgba(0, 0, 0, 0.8)" }} 
        className="capitalize text-xl font-semibold"
      >
        {name}
      </span>
    </div>
  );
}

function ActiveTab(props) {
  const { id, name, icon: IconComponent } = props;
  return (
    <div className="relative flex-1">
      <div
        className="border-t-2 border-r-2 border-l-2 border-primary rounded-t-md flex items-center gap-x-2 hover:text-primary py-1 px-2 bg-white shadow-lg"
      >
        <span>
          {IconComponent ? <IconComponent className="text-xl" /> : <LuLayoutDashboard className="text-xl" />}
        </span>
        <span
          style={{ color: "rgba(0, 0, 0, 0.8)" }} 
          className="text-xl font-semibold"
        >
          {name}
        </span>
      </div>
      <div
        className="absolute z-10 w-full flex justify-center"
        style={{ bottom: "-5px" }}
      >
        <div style={{ height: 2, width: "99.5%" }} className="bg-white"></div>
      </div>
    </div>
  );
}

export default LgScreenTabs;
