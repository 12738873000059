import { useContext } from "react";
import { Thead, THT, THTS } from "../../components";
import TableContext from "../Provider/TableContext";

function TableHead() {
  const { setOrder = () => {} } = useContext(TableContext);

  return (
    <Thead>
      <THTS txt="Registration No" field="registration_no" setOrder={setOrder} />
      <THTS txt="Model" field="make" setOrder={setOrder} />
      <THTS txt="VIN" field="vin" setOrder={setOrder} />
      <THTS txt="Fuel Capacity" field="fuel_capacity" setOrder={setOrder} />
      {/* <THTS txt="Assigned Driver" field="driver" setOrder={setOrder} /> */}
      <THTS txt="Revenue" field="revenue" setOrder={setOrder} />
      <THTS txt="Mileage" field="mileage" setOrder={setOrder} />
      <THTS txt="Fleet Number" field="fleet_no" setOrder={setOrder} />
      <THTS txt="Trailor Number" field="trailer_no" setOrder={setOrder} />

      <THT txt="Actions" />
    </Thead>
  );
}

export default TableHead;
