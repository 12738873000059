const init_company = {
  companyName: {
    value: "",
    label: "Company Name",
    placeholder: "Enter Company Name",
  },
  companyLocation: {
    value: "",
    label: "Company Location",
    placeholder: "Enter Company Location",
  },
  companyCode: {
    value: "",
    label: "Company Code",
    placeholder: "Enter Company Code",
  },
  mobileNumber: {
    value: "",
    label: "Mobile Number",
    placeholder: "Enter Mobile Number",
  },
  email: {
    value: "",
    label: "Company Email",
    placeholder: "Enter Email",
  },
  companyMission: {
    value: "",
    label: "Company Mission",
    placeholder: "Enter Company Mission",
  },
  companyVision: {
    value: "",
    label: "Company Vision",
    placeholder: "Enter Company Vision",
  },
  logo: {
    label: "Company Logo",
  },
};

export default init_company;

