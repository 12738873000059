import { useState } from "react";
import { TextInput, DateInput, SelectInput } from "../../Components/Inputs";
import init_vehicle from "../init_vehicle";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";
import useForm from "../../Components/hooks/useForm";
import useAxios from "../../../../Hooks/useAxios";

const Add = () => {
  const [input, setInput] = useState(init_vehicle);
  const navigate = useNavigate();
  const request = useAxios()


  const handleAdd = async (data)=>{
    let res = await request({
      method: 'POST',
      url: 'vehicles/add',
      body: data,
    });
    console.log(res)
    if(res !== 'error'){
      navigate('/dashboard/vehicles/fleets')
    }
  }

  const {handleValidation, validate } = useForm({
    input,
    submit: handleAdd,
  })

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Add Vehicle</h3>
      <div className=" mt-4 grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-6">
        <TextInput input={input} setInput={setInput} field="plate_no" validate={validate}/>
        <TextInput input={input} setInput={setInput} field="make" validate={validate} />
        <TextInput input={input} setInput={setInput} field="vin" validate={validate} />
        <TextInput input={input} setInput={setInput} field="fuel_capacity" validate={validate}/>
        <TextInput input={input} setInput={setInput} field="revenue" validate={validate}/>
        <TextInput input={input} setInput={setInput} field="mileage" validate={validate}/>
        <TextInput input={input} setInput={setInput} field="fleet_no" validate={validate}/>
        <TextInput input={input} setInput={setInput} field="trailer_no" validate={validate}/>
      </div>
      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/vehicles/fleets")}>Back</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default Add;
