import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NavBtn from "../NavBtn";
import { FaTruckMoving, FaChevronDown } from 'react-icons/fa';
import DropDown from "../../DropDown/DropDown";

const Vehicles = (props) => {
  const {
    exactPath = "/dashboard/vehicles",
    partialPath = "/vehicles",
    label = "Vehicles",
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectVehicle = (vehicle) => {
    navigate(vehicle.to);
  };

  const dropdownItems = [
    { to: '/dashboard/vehicles/fleets', label: 'Fleets' },
    { to: '/dashboard/vehicles/dispatch', label: 'Logistics' },
    { to: '/dashboard/vehicles/maintenance', label: 'Maintenance' },


  ];

  return (
    <div className="relative">
      <div className="flex flex-col w-full">
        <div className="flex items-center cursor-pointer w-full" onClick={toggleDropdown}>
          <NavBtn
            label={<span className="flex items-center">
              {label}
              <FaChevronDown
                className={`ml-14 transition-transform ${
                  isOpen ? "rotate-180" : "rotate-0"
                }`}
              />
            </span>}
            exactPath={exactPath}
            partialPath={partialPath}
            Icon={FaTruckMoving}
          />
        </div>
        {isOpen && (
          <DropDown
            isOpen={isOpen}
            items={dropdownItems}
            onSelect={handleSelectVehicle}
            activePath={location.pathname}
          />
        )}
      </div>
    </div>
  );
};

export default Vehicles;
