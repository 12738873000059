import { useEffect, useState } from "react";
import useApp from "../../Hooks/useApp";
import { useLocation, useNavigate } from "react-router-dom";
import { FaFonticons } from "react-icons/fa";

function BtnTemplate(props) {
  const [active, setActive] = useState(false);
  const { setSideBarOpen, device } = useApp();

  const {
    exactPath = null,
    partialPath = null,
    to = "",
    Icon = <FaFonticons className="text-xl" />,
    label = "Label",
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (to) {
      if (location.pathname === to) {
        setActive(true);
        return;
      }
    }

    if (partialPath) {
      if (location.pathname.includes(exactPath)) {
        setActive(true);
        return;
      }
    }

    setActive(false);
  }, [location, to, partialPath, exactPath]);

  const handleNavigate = () => {
    if (to) {
      navigate(to);
      if (device === "sm") {
        setSideBarOpen(false);
      }
    }
  };

  return (
    <OpenBtn label={label} active={active} Icon={Icon} click={handleNavigate} />
  );
}

function OpenBtn(props) {
  const { label, active, Icon, click } = props;

  return (
    <div
      className={`relative w-full rounded-md py-2 px-4 text-white flex items-center gap-x-2 cursor-pointer 
      ${active ? "bg-secondary" : ""}
      `}
      onClick={click}
    >
      <span
        className={
          active
            ? "absolute left-0 h-[80%] rounded-xl my-auto p-[3px] bg-white"
            : ""
        }
      ></span>
      <span className="text-2xl">
        <Icon />
      </span>
      <span className="text-lg font-normal ">{label}</span>
    </div>
  );
}

export default BtnTemplate;
