import React, { useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import TabBar from "../../../../../Components/TabBar";
import Devices from '../../../../../Features/Tables/Devices';
import DeviceLogs from '../../../../../Features/Tables/DeviceLogs';

const containerStyle = {
  width: "100%",
  height: "45vh",
};

const center = { lat: -1.286389, lng: 36.817223 };

const mapKey = process.env.REACT_APP_MAP_KEY;

function AllMonitoring() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: mapKey,
  });

  const [map, setMap] = useState(null);
  const [mapType, setMapType] = useState("roadmap");

  const onLoad = useCallback((mapInstance) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    mapInstance.fitBounds(bounds);
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleMapTypeChange = (type) => {
    setMapType(type);
    if (map) {
      map.setMapTypeId(type);
    }
  };

  const [active, setActive] = useState("1");
  const tabList = [
    { id: "1", value: "Device History" },
    { id: "2", value: "Device Logs" },
  ];

  return isLoaded ? (
    <div className="relative">
      {/* Custom buttons for switching map types */}
      <div className="absolute top-4 right-4 z-10 flex space-x-2">
        {["roadmap", "satellite", "hybrid", "terrain"].map((type) => (
          <button
            key={type}
            onClick={() => handleMapTypeChange(type)}
            className={`px-4 py-2 rounded-lg shadow-md transition duration-300 ${
              mapType === type
                ? "bg-secondary text-white"
                : "bg-white text-primary border border-gray-300 hover:bg-light_blue"
            }`}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </button>
        ))}
      </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={11}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapTypeId={mapType}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
        }}
      />

      <div className="bg-cream-white rounded-lg shadow-md p-4 mt-5">
        <TabBar list={tabList} active={active} setActive={setActive} />
        {active === "1" ? <Devices /> : active === "2" ? <DeviceLogs /> : null}
      </div>
    </div>
    
  ) : (
    <></>
  );
}

export default React.memo(AllMonitoring);
