import { useState } from "react";
import InfoRow from "./InfoRow";
import Pagination from "./Pagination";

import TableProvider from "./Provider";

function Table(props) {
  const {
    children,
    setIsLoading = () => {},
    setDocs = () => {},
    setIsFiltered = () => {},
    setShowPagination,
    showPagination,
  } = props;

  return (
    <TableProvider
      setIsFiltered={setIsFiltered}
      setIsLoading={setIsLoading}
      setDocs={setDocs}
      setShowPagination={setShowPagination}
      showPagination={showPagination}
    >
      <div className="w-full rounded-lg p-4 bg-white ">
        <InfoRow {...props} />
        <div className="min-w-full mt-2 overflow-x-scroll show-scrollbar rounded-t-md">
          <table className="min-w-full divide-y table-auto overscroll-y-none">
            {children}
          </table>
        </div>
        <Pagination {...props} />
      </div>
    </TableProvider>
  );
}

export default Table;
