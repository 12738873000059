import React, { useState } from "react";
import { TextInput } from "../../../Components/Inputs";
import Btn from "../../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../../Hooks/useAxios";
import useForm from "../../../Components/hooks/useForm";
import init_records from "../init_records";

const AddFuelRecord = () => {
  const [input, setInput] = useState(init_records);
  const navigate = useNavigate();
  const request = useAxios();

  const handleSubmit = async (data) => {
    const res = await request({
      method: "POST",
      url: "fuel/add",
      body: data,
    });
    if (res !== "error") {
      navigate("/dashboard/fuel");
    }
  };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-6 mt-12 rounded-lg bg-white">
      <h3 className="text-center text-2xl font-semibold text-gray-700 mb-6">Add Fuel Record</h3>
      
      <div className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
          <TextInput
            input={input}
            setInput={setInput}
            field="driver"
            validate={validate}
            label="Driver Name"
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="reg_number"
            validate={validate}
            label="Vehicle Registration Number"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
          <TextInput
            input={input}
            setInput={setInput}
            field="fuel_type"
            validate={validate}
            label="Fuel Type"
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="quantity"
            validate={validate}
            label="Fuel Quantity (Liters)"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
          <TextInput
            input={input}
            setInput={setInput}
            field="cost"
            validate={validate}
            label="Total Cost"
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="station"
            validate={validate}
            label="Fuel Station"
          />
        </div>

       
          <TextInput
            input={input}
            setInput={setInput}
            field="efficiency"
            validate={validate}
            label="Fuel Efficiency (Km/L)"
          />
        
      </div>

      <div className="mt-8 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/fuel")}>Back</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default AddFuelRecord;

