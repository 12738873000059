import React from 'react'
import RouteVehicles from '../../../../../Features/Tables/RouteVehicles'
import { useParams } from 'react-router-dom'
import useAxios from '../../../../../Hooks/useAxios'
import { useState, useEffect } from 'react'

const Index = () => {

  const { id } = useParams()
  const request = useAxios()
  const [vehicles, setVehicles] = useState({})

  useEffect(()=>{
    getRouteVehicles()
  },[])

  const getRouteVehicles = async()=>{
    const res = await request({
      method: 'get',
      url: 'routes/single-route',
      params: {
        id: parseInt(id),
        show_loading: false,
      }
    })
    if(res !=='error'){
      setVehicles(res)
    }
    
  }
  return (
    <div className='mt-4'>
      < RouteVehicles />
    </div>
  )
}

export default Index