import Limit from "./Limit";
import Navigation from "./Navigation";

function Pagination(props) {
  const { showPagination = true } = props;

  if (!showPagination) {
    return null;
  }

  return (
    <div className="w-full bg-white px-4 flex flex-wrap gap-6 justify-center items-center rounded-b-md py-2">
      <Limit {...props} />
      <Navigation {...props} />
    </div>
  );
}

export default Pagination;
