import React from "react";
import AddDevice from '../../../Features/Forms/Devices/Add'

const Add = () => {
  return <div>
    <AddDevice />
  </div>;
};

export default Add;
