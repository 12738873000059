import React from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { getDecryptedData } from "../Util/store";
import useApp from "../Hooks/useApp";

const Protected = () => {
  const navigate = useNavigate();
  const { user, token } = useApp();
  const storedToken = getDecryptedData("token");
  const savedUser = getDecryptedData("user");

  if (user || savedUser || token || storedToken) {
    return <Outlet />;
  } else {
    <Navigate to="" />;
  }
};

export default Protected;
