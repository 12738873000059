import React, { useEffect, useState } from "react";
import { TextInput, SelectInput, DateInput } from "../../Components/Inputs";
import init_records from "../init_records";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import useForm from "../../Components/hooks/useForm";
import SearchableSelect from '../../Components/Inputs/SearchableSelect';
import useApp from "../../../../Hooks/useApp";

const Edit = () => {
  const [input, setInput] = useState(init_records);
  const [vehicles, setVehicles] = useState({});
  const { user } = useApp();
  const navigate = useNavigate();
  const { id } = useParams(); 
  const request = useAxios();

  useEffect(() => {
    getFuelRecord(); 
    getConfig();    
  }, []);

  const getFuelRecord = async () => {
    let res = await request({
      method: "GET",
      url: `fuel/${id}`, 
    });
    if (res !== "error") {
      setInput(res); 
    }
  };

  const handleUpdate = async (data) => {
    let res = await request({
      method: "PUT",
      url: `fuel/${id}/update`,
      body: data,
    });
    if (res === "error") return;
    navigate("/dashboard/fuel"); 
    };

  const { handleValidation, validate } = useForm({
    input,
    submit: handleUpdate,
  });

  async function getConfig() {
    let res = await request({
      method: "GET",
      url: "config",
      show_loading: false,
      show_error: false,
    });
    if (res !== "error") {
    }
  }

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Edit Fuel Record</h3>
      <div className="mt-4">
        <div className="flex gap-5 items-center max-md:flex-col">
          <DateInput
            input={input}
            setInput={setInput}
            field="fuel_date"
            validate={validate}
            label="Fuel Date"
          />
          <SearchableSelect
            input={input}
            setInput={setInput}
            field="vehicle_id"
            validate={validate}
            url="Vehicles/search-vehicle"
            setOption={setVehicles}
          >
            {vehicles?.docs?.map((vehicle, i) => (
              <option value={vehicle?.id} key={i} label={vehicle.plate_no}>
                {vehicle?.plate_no}
              </option>
            ))}
          </SearchableSelect>
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="fueled_by"
            validate={validate}
            label="Fueled By"
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="trip_id"
            validate={validate}
            label="Trip"
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="fuel_amount"
            validate={validate}
            label="Fuel Amount (Liters)"
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="price_per_litre"
            validate={validate}
            label="Price Per Litre"
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="cost"
            validate={validate}
            label="Total Cost"
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="filling_station"
            validate={validate}
            label="Filling Station"
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="nano_receipt"
            validate={validate}
            label="Receipt"
          />
        </div>
      </div>

      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/fuel")}>Back</Btn>
        <Btn onClick={handleValidation}>Update</Btn>
      </div>
    </div>
  );
};

export default Edit;

