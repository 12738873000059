import TableHead from "./Table/TableHead";

import useTable from "./Provider/useTable";
import TableRow from "./Table/TableRow";

import { Table, TBody } from "../components";

import { useNavigate } from "react-router-dom";
import TableContext from "./Provider/TableContext";
import DeleteModal from "./Table/DeleteModal";
import CancelModal from "./Table/CancelModal";
import AssignDeviceModal from "./Table/AssignDeviceModal";

function Logistics() {
  const {
    limit,
    setLimit,
    docs,
    setDocs,
    isLoading,
    setIsLoading,
    isFiltered,
    setIsFiltered,
    pagination,
    genders,
    dobRange,
    setDobRange,
    selectedGenders,
    setSelectedGenders,
    doaRange,
    setDoaRange,
    setOrder,
    clearFilters,
    systemId,
    setSystemId,
    refetchDocs,
    showDeleteModal,
    handleAssign,
    showAssignModal,
    setShowAssignModal,
    showCancelModal,
    setShowCancelModal,
    setShowDeleteModal,
    setVehicleId,
    handleDelete,
    handleCancel,
    devices,
    vehicles,
  } = useTable();

  console.log('res',showAssignModal);

  const navigate = useNavigate();

  return (
    <>
      <TableContext.Provider
        value={{
          limit,
          setLimit,
          docs,
          setDocs,
          isLoading,
          setIsLoading,
          isFiltered,
          setIsFiltered,
          pagination,
          genders,
          dobRange,
          setDobRange,
          selectedGenders,
          setSelectedGenders,
          doaRange,
          setDoaRange,
          setOrder,
          clearFilters,
          systemId,
          setSystemId,
          refetchDocs,
          showDeleteModal,
          setShowDeleteModal,
          handleDelete,
          showCancelModal,
          showAssignModal,
          setShowAssignModal,
          setShowCancelModal,
          handleAssign,
          setVehicleId,
          handleCancel,
          devices,
          vehicles,
        }}
      >
        <Table
          label="Logistics"
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setDocs={setDocs}
          isFiltered={isFiltered}
          handleAdd={() => navigate("add")}
          setIsFiltered={setIsFiltered}
          handleRemoveFilters={clearFilters}
          pagination={pagination}
          url="Logistics/search"
        >
          <TableHead setOrder={setOrder} />
          <TBody>
            {docs.map((doc, i) => {
              return <TableRow key={doc?.id || i} doc={doc} />;
            })}
          </TBody>
        </Table>
      </TableContext.Provider>
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          handleDelete={handleDelete}
          systemId={systemId}
        />
      )}
       {showCancelModal && (
        <CancelModal
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
          handleCancel={handleCancel}
        />
      )}
       {showAssignModal && (
        <AssignDeviceModal
        showAssignModal={showAssignModal} 
        setShowAssignModal={setShowAssignModal} 
        handleAssign={handleAssign} 
        devices={devices}
        vehicles={vehicles}
          
        />
      )}
    </>
  );
}

export default Logistics;
