import BasicModal from "./BasicModal";
import Lottie from "lottie-react";

import loadingAnimation from "../../Assets/animations/loading.json";

function LoadingModal({ loadingModal }) {
  const { isLoading } = loadingModal;

  return (
    <BasicModal showModal={isLoading} showHeader={false}>
      <div
        className=" flex flex-col w-full justify-center "
        style={{ backgroundColor: "rgba(0,0,0,0)" }}
      >
        <div className=" w-full flex justify-center" style={{ width: "10em" }}>
          <Lottie animationData={loadingAnimation} />
        </div>
        <div
          className=" flex justify-center py-2 text-3xl font-semibold animate-pulse "
          style={{ color: "rgba(0,0,0,0.2)" }}
        >
          ... Loading
        </div>
      </div>
    </BasicModal>
  );
}

export default LoadingModal;
