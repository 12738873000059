import React from "react";
import Requests from "../../../Features/Tables/Requests";

const All = () => {
  return (
    <div>
      <Requests />
    </div>
  );
};

export default All;
