import React from "react";
import AddVehicleReport from '../../../../Features/Forms/Reports/VehicleReport/Add'

const Add = () => {
  return <div>
    <AddVehicleReport />
  </div>;
};

export default Add;
