import Input from "./Utils/Input";

import inputClass from "../../utils/input.style";

import useInput from "../hooks/useInput";

function SelectInput(props) {
  const {
    field = "",
    input,
    setInput,
    mt,
    mb,
    validate,
    exValidate,
    children,
  } = props;

  const { handleChange } = useInput({
    input,
    setInput,
    field,
    validate,
    exValidate,
  });

  if (typeof input !== "object") {
    console.error("input must be an object");
    return null;
  }

  return (
    <Input mt={mt} mb={mb} input={input[field] || null} validate={validate}>
      <select
        className={`${inputClass.base} bg-transparent`}
        value={input[field]?.value || ""}
        placeholder={input[field]?.placeholder || ""}
        onChange={handleChange}
        disabled={input[field]?.disabled || false}
      >
        {children}
      </select>
    </Input>
  );
}

export default SelectInput;
