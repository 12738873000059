import { useEffect, useState } from "react";

import useAxios from "../../../../Hooks/useAxios";
import { useParams } from "react-router-dom";

const initDoc = [];

function useTable() {
  const [limit, setLimit] = useState(10);
  const [docs, setDocs] = useState(initDoc);
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const request = useAxios();
  const { id } = useParams()

  // Pagination
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);

  const [order, setOrder] = useState("id-desc");

  // Filters
  const [genders, setGenders] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);

  useEffect(() => {
    getDocs({ page: page, limit: limit });
  }, [limit, selectedGenders, order]);

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    if (selectedGenders.length === 0) {
      setIsFiltered(false);
    }
  }, [selectedGenders]);

  async function getDocs({ page, limit }) {
    let res = await request({
      method: "GET",
      url: "vehicles/all-vehicles",
      params: {
        page: page,
        limit: limit,
        order,
        id: parseInt(id),
        // gender: selectedGenders.join(","),
      },
      show_loading: false,
      loadingFunc: setIsLoading,
    });

    if (res === "error") {
      return;
    }
    if (selectedGenders.length > 0) {
      setIsFiltered(true);
    }

    setTotal(res?.pagination?.total || 0);
    setPages(res?.pagination?.pages || 0);
    setHasNextPage(res?.pagination?.hasNextPage || false);
    setHasPrevPage(res?.pagination?.hasPrevPage || false);
    setDocs(res?.docs || initDoc);
  }

  async function getConfig() {
    let res = await request({
      method: "GET",
      url: "config",
      show_error: false,
      show_loading: false,
    });

    if (res === "error") {
      return;
    }

    setGenders(res?.gender || []);
  }

  async function clearFilters() {
    setSelectedGenders([]);
    setIsFiltered(false);
  }

  function handleNext() {
    if (hasNextPage) {
      setPage((c) => c + 1);
      getDocs({ page: page + 1, limit });
    }
  }

  function handlePrev() {
    if (hasPrevPage) {
      setPage((c) => c - 1);
      getDocs({ page: page - 1, limit });
    }
  }

  return {
    limit,
    setLimit,
    docs,
    setDocs,
    isLoading,
    setIsLoading,
    isFiltered,
    setIsFiltered,
    pagination: {
      page,
      total,
      pages,
      hasNextPage,
      hasPrevPage,
      handleNext,
      handlePrev,
      limit,
      setLimit,
      total_docs: docs?.length || 0,
    },
    selectedGenders,
    setSelectedGenders,
    genders,
    setGenders,
    setOrder,
    clearFilters,
  };
}

export default useTable;

