import React, { useEffect, useState } from "react";
import { TextInput, SelectInput, DateInput } from "../../Components/Inputs";
import initDoc from "../initDoc";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";
import useForm from "../../Components/hooks/useForm";
import SearchableSelect from '../../Components/Inputs/SearchableSelect'

const Edit = () => {
  const [input, setInput] = useState(initDoc);
  const navigate = useNavigate();
  const request = useAxios();
  const { id } = useParams();

  const [vehicles, setVehicles] = useState({});
  const [members, setMembers] = useState({});
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    getMaintenanceRecords();
  }, []);

  const getMaintenanceRecords = async()=>{
    let res = await request({
      method: 'get',
      url: 'vehicles/maintenance/single-record',
      params: {
        id: parseInt(id)
      }
    });
    if (res === 'error') return;
    const inputCpy = {...initDoc}
    inputCpy.vehicle_id.value = res?.vehicle_id;
    inputCpy.name.value = res?.name;
    inputCpy.last_maintenance_date.value = res?.last_maintenance_date;
    inputCpy.requested_by.value = res?.requested_by;
    inputCpy.maintenance_cost.value = res?.maintenance_cost;
    inputCpy.performed_by.value = res?.performed_by;
    inputCpy.actual_maintenance_date.value = res?.actual_maintenance_date;
    inputCpy.next_maintenance_date.value = res?.next_maintenance_date;
    inputCpy.description.value = res?.description;

    setInput(inputCpy);


  }

  const handleSubmit = async(data)=>{
    setIsLoading(true);
    let res = await request({
      method: 'PUT',
      url: 'vehicles/maintenance/update',
      body: {
        id: parseInt(id),
        ...data,
      },
      show_Loading: true,
      loadingFunc: setIsLoading,
    });
    if(res !== 'error'){
      navigate('/dashboard/vehicles/maintenance')
    }
  }

  const { handleValidation, validate } = useForm({
    input,
    submit: handleSubmit,
  });

  return (
    <div className="shadow-2xl w-[85%] m-auto border-t p-4 mt-12">
      <h3 className="text-center text-xl font-semibold">Edit Maintenance Records</h3>
      <div className="mt-4">
        <div className="flex gap-5 items-center max-md:flex-col">
        <SearchableSelect
            input={input}
            setInput={setInput}
            field="vehicle_id"
            validate={validate}
            url="vehicles/search-vehicle"
            setOptions={setVehicles}
          >
            {vehicles?.docs?.map((vehicle, i) => (
              <option value={vehicle?.id} key={i} label={vehicle.plate_no}>
                {vehicle?.plate_no}
              </option>
            ))}
          </SearchableSelect>
          <TextInput
            input={input}
            setInput={setInput}
            field="name"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <DateInput
            input={input}
            setInput={setInput}
            field="last_maintenance_date"
            validate={validate}
          />
          <SearchableSelect
            input={input}
            setInput={setInput}
            field="requested_by"
            validate={validate}
            url="members/search"
            setOptions={setMembers}
          >
            {members?.docs?.map((member, i) => (
              <option value={member?.id} key={i} label={member.name}>
                {member?.name}
              </option>
            ))}
          </SearchableSelect>
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="maintenance_cost"
            validate={validate}
          />
          <TextInput
            input={input}
            setInput={setInput}
            field="performed_by"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <DateInput
            input={input}
            setInput={setInput}
            field="actual_maintenance_date"
            validate={validate}
          />
          <DateInput
            input={input}
            setInput={setInput}
            field="next_maintenance_date"
            validate={validate}
          />
        </div>

        <div className="flex gap-5 items-center max-md:flex-col">
          <TextInput
            input={input}
            setInput={setInput}
            field="description"
            validate={validate}
          />
        </div>
      </div>

      <div className="mt-4 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/vehicles/maintenance")}>Back</Btn>
        <Btn   onClick={handleValidation}
          disabled={isLoading}
        >
          {isLoading ? 'updating...': 'Submit'}</Btn>
      </div>
    </div>
  );
};

export default Edit;


