import React, { useState } from "react";
import { TextInput, TextArea } from "../../Components/Inputs";
import init_po from "../init_po";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate } from "react-router-dom";

const Add = () => {
  const [input, setInput] = useState(init_po);
  const navigate = useNavigate();
  return (
    <div className="border-t shadow-2xl w-[85%] m-auto mt-12 p-5 rounded-md">
      <h3 className="text-center text-xl font-semibold text-primary">
        Add Purchase Order
      </h3>
      <div className="mt-5">
        <TextInput input={input} setInput={setInput} field="item_name" />
        <div className="flex gap-5 max-md:flex-col max-md:w-full">
          <TextInput input={input} setInput={setInput} field="quantity" />
          <TextInput input={input} setInput={setInput} field="total_amount" />
        </div>
        <TextArea input={input} setInput={setInput} field="description" />
      </div>
      <div className="mt-5 flex justify-between">
        <Btn onClick={() => navigate("/dashboard/purchase-orders")}>Back</Btn>
        <Btn onClick={() => navigate("/dashboard/purchase-orders")}>Submit</Btn>
      </div>
    </div>
  );
};

export default Add;
