import React, { useEffect, useState } from "react";
import { TextInput, TextArea, useForm } from "../../Components/Inputs";
import init_role from "../init_role";
import Btn from "../../../../Components/Buttons/Btn";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../Hooks/useAxios";

const Edit = () => {
  const [input, setInput] = useState(init_role);
  const navigate = useNavigate();
  const { id } = useParams();
  const request = useAxios();

  useEffect(() => {
    getRole();
  }, []);

  async function getRole() {
    const res = await request({
      method: "GET",
      url: `roles/single-role`,
      params: {
        id: parseInt(id),
      },
      show_loading: false,
      show_error: false,
    });

    if (res === "error") {
      return;
    }

    const initCpy = { ...init_role };

    initCpy.name.value = res?.name;
    initCpy.description.value = res?.description;

    setInput(initCpy);
  }

  async function handleUpdate(data) {
    let res = await request({
      method: "PUT",
      url: "roles/update",
      body: {
        id: parseInt(id),
        ...data,
      },
    });

    if (res !== "error") {
      navigate("/dashboard/roles");
    }
  }

  const { handleValidation, validate } = useForm({
    input,
    submit: handleUpdate,
  });

  return (
    <div className="bg-white p-4 mt-6 w-[90%] m-auto shadow-2xl">
      <h3 className="text-center font-semibold text-xl text-primary">
        Edit Role
      </h3>
      <div className="mt-5">
        <div className="w-full">
          <TextInput input={input} setInput={setInput} field="name" />
          <TextArea input={input} setInput={setInput} field="description" />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <Btn onClick={() => navigate("/dashboard/roles")}>Cancel</Btn>
        <Btn onClick={handleValidation}>Submit</Btn>
      </div>
    </div>
  );
};

export default Edit;
