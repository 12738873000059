function SelectInput(props) {
  const { children, active, setActive } = props;

  return (
    <div className={`w-full flex flex-col border-2 rounded-md h-10`}>
      <select
        className="rounded-lg  px-4 w-full h-full  focus:none focus:ring-2 focus:ring-primary bg-transparent "
        value={active}
        onChange={(e) => setActive(e.target.value)}
      >
        {children}
      </select>
    </div>
  );
}

export default SelectInput;
