import React from "react";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  LineChart,
  Line,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const ExpenseDashboard = () => {
  // Example Data for Fuel Expenses
  const fuelExpenseData = [
    { month: "Jan", fuelCost: 1200 },
    { month: "Feb", fuelCost: 1400 },
    { month: "Mar", fuelCost: 1350 },
    { month: "Apr", fuelCost: 1500 },
    { month: "May", fuelCost: 1600 },
  ];

  // Example Data for Maintenance Expenses
  const maintenanceExpenseData = [
    { name: "Preventive Maintenance", value: 4000 },
    { name: "Repair Maintenance", value: 2500 },
  ];

  // Example Data for Overall Expenses
  const expenseBreakdownData = [
    { name: "Fuel", value: 5000 },
    { name: "Maintenance", value: 6500 },
    { name: "Other Expenses", value: 2000 },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <div className="p-8 bg-gray-100">
      <h1 className="text-4xl font-bold mb-8">Summary</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-2">Total Fuel Expenses</h2>
          <p className="text-2xl font-bold">$7,100</p>
        </div>
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-2">
            Total Maintenance Expenses
          </h2>
          <p className="text-2xl font-bold">$6,500</p>
        </div>
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-2">
            Other Operational Costs
          </h2>
          <p className="text-2xl font-bold">$2,000</p>
        </div>
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-2">Total Expenses</h2>
          <p className="text-2xl font-bold">$15,600</p>
        </div>
      </div>

      {/* Fuel Expense Over Time */}
      <div className="mb-8 mt-8 bg-white p-5 rounded-lg">
        <h2 className="text-2xl font-semibold mb-4">Fuel Expenses Over Time</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={fuelExpenseData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="fuelCost" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Maintenance Expense Breakdown */}
      <div className="mb-8 bg-white p-5 rounded-lg">
        <h2 className="text-2xl font-semibold mb-4">
          Maintenance Expenses Breakdown
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={maintenanceExpenseData}
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#82ca9d"
              dataKey="value"
              label={({ name, percent }) =>
                `${name}: ${(percent * 100).toFixed(0)}%`
              }
            >
              {maintenanceExpenseData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>

      {/* Overall Expense Breakdown */}
      <div className="mb-8 bg-white p-5 rounded-lg">
        <h2 className="text-2xl font-semibold mb-4">
          Overall Expense Breakdown
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={expenseBreakdownData}
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              labelLine={false}
              label={({ name, percent }) =>
                `${name}: ${(percent * 100).toFixed(0)}%`
              }
              dataKey="value"
            >
              {expenseBreakdownData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>

      {/* Bar Chart for Various Expenses */}
      <div className="mb-8 bg-white p-5 rounded-lg">
        <h2 className="text-2xl font-semibold mb-4">
          Expense Breakdown by Type
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={expenseBreakdownData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Additional Metrics */}
    </div>
  );
};

export default ExpenseDashboard;
