import React from "react";
import NavBtn from "../NavBtn";
import { IoIosLogOut } from "react-icons/io";

const LogOut = (props) => {
  const {
    exactPath = null,
    to = "/",
    partialPath = null,
    label = "Log Out",
  } = props;

  return (
    <div className="p-2 bg-[#FFFFFF33] rounded-lg">
      <NavBtn
        label={label}
        exactPath={exactPath}
        partialPath={partialPath}
        to={to}
        Icon={IoIosLogOut}
      />
    </div>
  );
};

export default LogOut;
