import React from "react";
import EditMember from "../../../Features/Forms/Members/Edit";

const Edit = () => {
  return (
    <div>
      <EditMember />
    </div>
  );
};

export default Edit;
