import React from "react";
import Devices from '../../../Features/Tables/Devices'

const All = () => {
  return <div>
    <Devices />
  </div>;
};

export default All;
