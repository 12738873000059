import { useEffect, useState } from "react";
import useAxios from "../../../../Hooks/useAxios";

const initDoc = [
  {
    id: "1",
    dispatch_id: "D12345",
    vehicle_reg: "KDQ 112B",
    driver: "Gilbert Osoro",
    start_location: "Nairobi",
    end_location: "Mombasa",
    scheduled_date: "2024-09-24",
    departure_time: "2024-09-24T08:00:00+03:00",
    arrival_time: "2024-09-24T14:00:00+03:00",
    status: "Scheduled",
    notes: "On-time departure expected",
  },
];

function useTable() {
  const [limit, setLimit] = useState(10);
  const [docs, setDocs] = useState(initDoc);
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);
  const [vehicleId, setVehicleId] = useState(null);
  const [order, setOrder] = useState("id-desc");
  const [systemId, setSystemId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);

  const request = useAxios();

  useEffect(() => {
    getDocs({ page, limit });
  }, [page, limit, order]);

  async function getDocs({ page, limit }) {
    setIsLoading(true); // Set loading state
    try {
      const res = await request({
        method: "GET",
        url: "scheduling/all",
        params: { page, limit, order },
        show_loading: false,
      });

      if (res === "error") {
        throw new Error("Error fetching documents");
      }

      setTotal(res?.pagination?.total || 0);
      setPages(res?.pagination?.pages || 0);
      setHasNextPage(res?.pagination?.hasNextPage || false);
      setHasPrevPage(res?.pagination?.hasPrevPage || false);
      setDocs(res?.docs || initDoc);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  }

  async function clearFilters() {
    setIsFiltered(false);
    // Additional logic to clear specific filters if needed
  }

  function handleNext() {
    if (hasNextPage) setPage(prevPage => prevPage + 1);
  }

  function handlePrev() {
    if (hasPrevPage) setPage(prevPage => prevPage - 1);
  }

  async function handleDelete(id) {
    try {
      const res = await request({
        method: "DELETE",
        url: "scheduling/delete",
        params: { id: parseInt(id) },
      });

      if (res !== "error") {
        setShowDeleteModal(false);
        refetchDocs();
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  }

  async function handleCancel() {
    try {
      const res = await request({
        method: "PUT",
        url: "scheduling/change-status",
        body: { id: parseInt(vehicleId), status: "cancelled" },
      });

      if (res !== "error") {
        setShowCancelModal(false);
        refetchDocs();
      }
    } catch (error) {
      console.error("Error cancelling status:", error);
    }
  }

  async function handleAssign() {
    try {
      const res = await request({
        method: "PUT",
        url: "scheduling/change-status",
        body: { id: parseInt(vehicleId), status: "assigned" },
      });

      console.log('response:', res);

      if (res !== "error") {
        console.log("Device Assigned");
        setShowAssignModal(false);
        refetchDocs();
      }
    } catch (error) {
      console.error("Error assigning device:", error);
    }
  }

  const refetchDocs = () => getDocs({ page, limit });

  return {
    limit,
    setLimit,
    docs,
    setDocs,
    isLoading,
    setIsLoading,
    isFiltered,
    setIsFiltered,
    pagination: {
      page,
      total,
      pages,
      hasNextPage,
      hasPrevPage,
      handleNext,
      handlePrev,
      limit,
      total_docs: docs.length || 0,
    },
    setOrder,
    clearFilters,
    systemId,
    setSystemId,
    setVehicleId,
    refetchDocs,
    showDeleteModal,
    setShowDeleteModal,
    showCancelModal,
    setShowCancelModal,
    showAssignModal,
    setShowAssignModal,
    handleDelete,
    handleCancel,
    handleAssign,
  };
}

export default useTable;

