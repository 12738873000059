const init_vehicle = {
  plate_no: {
    value: '',
    label: 'Registration No',
    placeholder: 'Enter vehicle registration number',
  },
  make: {
    value: '',
    label: 'Model',
    placeholder: 'Enter vehicle model',
  },
  vin: {
    value: '',
    label: 'VIN',
    placeholder: 'Enter vehicle VIN',
  },
  fuel_capacity: {
    value: '',
    label: 'Fuel Capacity',
    placeholder: 'Enter fuel capacity',
  },
  revenue: {
    value: '',
    label: 'Revenue',
    placeholder: 'Enter revenue generated by the vehicle',
  },
  mileage: {
    value: '',
    label: 'Mileage',
    placeholder: 'Enter vehicle mileage',
  },
  fleet_no: {
    value: '',
    label: 'Fleet Number',
    placeholder: 'Enter fleet number',
  },
  trailer_no: {
    value: '',
    label: 'Trailer Number',
    placeholder: 'Enter trailer number (if applicable)',
  },
};

export default init_vehicle;
