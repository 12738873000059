import React from "react";
import AddMaintenanceData from '../../../Features/Forms/Maintanance/Add'

const Add = () => {
  return <div>
    <AddMaintenanceData />
  </div>;
};

export default Add;
