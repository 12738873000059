import React from "react";
import AddTripReport from '../../../../Features/Forms/Reports/TripReport/Add'

const Add = () => {
  return <div>
    <AddTripReport />
  </div>;
};

export default Add;
