import { TR, TDT, TA, TD } from "../../components";
import { FaRegEdit, FaEye, FaCheckCircle } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import TableContext from "../Provider/TableContext";
import { RiDeleteBin5Line } from "react-icons/ri";

function TableRow(props) {
  const { doc } = props;
  const navigate = useNavigate();
  const {
    setShowApproveModal,
    setShowCancelModal,
    setShowDeleteModal,
    setOrderId,
  } = useContext(TableContext);

  function showDelete() {
    setOrderId(doc?.id);
    setShowDeleteModal(true);
  }
  function showApprove() {
    setOrderId(doc?.id);
    setShowApproveModal(true);
  }
  function showCancel() {
    setOrderId(doc?.id);
    setShowCancelModal(true);
  }

  return (
    <TR>
      <TDT name="REQUEST ID" txt={doc?.request_id || "--"} />
      <TDT name="ITEM NAME" txt={doc?.name || ""} />
      <TDT name="QUANTITY" txt={doc?.quantity || "--"} />
      <TDT name="TOTAL AMOUNT" txt={doc?.amount || "--"} />
      <TDT name="Description" txt={doc?.description || "--"} />
      <TDT name="APPROVED BY" txt={doc?.quantity || "--"} />
      <TDT name="CREATED BY" txt={doc?.quantity || "--"} />
      <TDT
        name="STATUS"
        txt={
          <div
            className={`rounded-2xl w-fit px-4 ${
              doc?.status === "pending"
                ? "bg-red"
                : doc?.status === "approved"
                ? "bg-green-500"
                : "bg-secondary"
            } `}
          >
            <p className={`text-md text-white`}>{doc?.status || "--"}</p>
          </div>
        }
      />
      <TA name="ACTIONS" id={doc?.id}>
        <div className="flex flex-col gap-4">
          {doc?.status === "pending" && (
            <div
              className="cursor-pointer"
              onClick={() => navigate(`edit/${doc?.id}`)}
            >
              <p className="text-center text-xl flex gap-2">
                <FiEdit2 className="text-blue-500 text-2xl text-center" />
                <span>Edit</span>
              </p>
            </div>
          )}
          {doc?.status !== "approved" && doc?.status !== "cancelled" && (
            <div className="cursor-pointer" onClick={showApprove}>
              <p className="text-center text-xl flex gap-2">
                <FaCheckCircle className="text-green-500 text-2xl text-center" />
                <span>Approve</span>
              </p>
            </div>
          )}
          {doc?.status === "pending" && (
            <div className="cursor-pointer" onClick={showCancel}>
              <p className="text-center text-xl flex gap-2">
                <MdCancel className="text-2xl text-center text-red" />
                <span>Cancel</span>
              </p>
            </div>
          )}
          {doc?.status === "pending" && (
            <div className="cursor-pointer" onClick={showDelete}>
              <p className="text-center text-xl flex gap-2">
                <RiDeleteBin5Line className="text-2xl text-center text-red" />
                <span>Delete</span>
              </p>
            </div>
          )}
        </div>
      </TA>
    </TR>
  );
}

export default TableRow;
