import React from "react";
import EditVehicle from '../../../Features/Forms/Vehicles/Edit'

const Edit = () => {
  return (
    <div>
      <EditVehicle />
    </div>
  );
};

export default Edit;
